import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "./bar-chart.css";

const chartOptions = {
	"averageAttention": {
		text: "Attention Minutes Per User",
		color: "var(--yellow)",
		pointText: "Min Per User"
	},
	"distinctUsers": {
		text: "Distinct Users",
		color: "var(--teal)",
		pointText: "Users"
	},
	"engagements": {
		text: "Engagements",
		color: "var(--green)",
		pointText: "Engagements"
	},
	"attentionMinutes": {
		text: "Attention Minutes",
		color: "var(--orange)",
		pointText: "Attention Min"
	}
}

const BarChart = ({ props }) => {
	const categoryPairedData = {};

	const xCategories = [];
	const displayData = [];

	const chartOption = chartOptions[props.dataType];
	// Populate xCategories and displayData from props.data
	for (const [key, value] of Object.entries(props.data || {})) {
		xCategories.push(key);
		displayData.push(value);
	}

	let horizontal = false;	
	const chartComponentRef = useRef(null);
	const initialChartOptions = {
		chart: {
			type: "column",
			height: null,
			width: null,
			marginTop: 75, // Increase top margin
			marginRight: 50, // Increase right margin
			marginBottom: 100, // Increase bottom margin
			marginLeft: 50, // Increase left margin
			spacingTop: 10,
			spacingRight: 10,
			spacingBottom: 10,
			spacingLeft: 10,
			backgroundColor: "var(--white)",
			events: {
				click: function (event) {
					// Update chart options
					console.log('Chart clicked', event);

                    if (chartComponentRef.current) {
						if (horizontal) {
							horizontal = false;
							chartComponentRef.current.chart.update({
								chart: {
									type: "column",
									marginLeft: 50,
									marginBottom: 100,
									marginTop: 75, // Increase top margin
								}
							});
						} else {
							
							horizontal = true;
							chartComponentRef.current.chart.update({
								chart: {
									type: "bar",
									marginLeft: 100,
									marginBottom: 50,
									marginTop: 50, // Increase top margin
								}
							});
						}
                    }
				}
			}
		},
		title: {
			text: chartOption.text,
			align: "left",
			x: 10, // Position the title at the left edge
			marginTop: 10,

			style: {
				fontSize: "1em",
				fontFamily: "Inter",
			},
		},

		xAxis: {
			title: {
				text: "" /*set to an empty string to remove labels. Original code commented out below */,

				//  text: `<span style="font-size: .8em; color: #333;">${
				//    props.xAxisName || "X Axis"
				//  }</span>`,
				//  useHTML: true,
			},
			categories: xCategories.length ? xCategories : "",
			labels: {
				formatter: function () {
					return this.value.length > 25
						? `${this.value.substring(0, 25)}...`
						: this
							.value; /*CHANGE THE VALUE TO PULL IN THE THE INDEX OF THE VALUE FOR this.value AND LOOK UP IN TABLE FOR WHAT THAT VALUE IS, RETURN THE NUMBER AND APPEND 01 for vertical */
				},
				style: {
					fontSize: ".5em",
				},
				step: 1 /*show all labels*/,
			},
			
		},
		yAxis: {
			title: {
				text: "" /*Set to an empty string to remove labels.  Original Code 


      //  text: `<span style="font-size: .8em;  color: #333;">${
      //    props.yAxisName || "Y Axis"
      //  }</span>`,
      //  useHTML: true,
      //  x: 10 /*Adding a little padding to the axis title",*/,
			},

			type: "linear",
			labels: {
				style: {
					fontSize: ".6em",
				},
			},
		},

		credits: {
			enabled: false,
		},
		series: [
			{
				type: "column",
				color: chartOption.color,
				data: displayData.length
					? displayData
					: Object.values(categoryPairedData),
			},
		],
		tooltip: {
			headerFormat:
				'<span style="font-size: 1em"><b>{point.key}</b></span><br/>',
			pointFormat:
				`<span style="font-size: .8em; display: block;">{point.y} {chartOption.pointText}</span>`,
			backgroundColor: "rgba(255, 255, 255, 0.85)",
			borderWidth: 1,
			borderColor: "#ccc",
			shadow: false,
			style: {
				fontSize: "14px",
				fontFamily: "Inter",
			},
			shared: true,
		},
		legend: {
			enabled: false,
		},
	};

	return (
		<div className="bar-chart-container">
			<HighchartsReact highcharts={Highcharts} options={initialChartOptions} ref={chartComponentRef}/>
		</div>
	);
};

export default BarChart;
