import React, { useState, useEffect, useRef } from 'react';
import './dropdown.css';

const Dropdown = ({ props, onClick }) => {
	const dropdownRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState('');
	const [subMenuOpen, setSubMenuOpen] = useState(null);
	const [options, setOptions] = useState({});

	useEffect(() => {
		setOptions(props.shows);
	}, [props.shows]);

	const handleShowClick = (show) => {
		setSubMenuOpen(subMenuOpen === show ? null : show);
	};

	const handleOptionClick = (show, option) => {
		setSelectedOption(`${show} : ${option}`);
		onClick(`${show}_${option}`);
		setIsOpen(false);
	};

	const currentSelection = selectedOption || 'Select Event >';

	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsOpen(false);
			setSubMenuOpen(null);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div className="dropdown" ref={dropdownRef}>
			<button onClick={() => setIsOpen(!isOpen)} className="dropdown-toggle">
				{currentSelection}
			</button>
			{isOpen && (
				<div className="dropdown-menu">
					{Object.keys(options).map((show) => (
						<div key={show}>
							<button className={`dropdown-show ${subMenuOpen === show ? 'active' : ''}`} onClick={() => handleShowClick(show)}>
								{show}
							</button>
							{subMenuOpen === show && (
								<div className="sub-menu">
									{/* Render "Overall" first if it exists */}
									{/* {options[show].includes("Overall") && (
                    <button
                      key="Overall"
                      className="dropdown-option"
                      onClick={() => handleOptionClick(show, "Overall")}
                    >
                      Overall
                    </button>
                  )} */}
									{/* Render other items after "Overall" */}
									{options[show]
										// .filter((item) => item !== "Overall") // Filter out "Overall"
										.map((item) => (
											<button key={item} className="dropdown-option" onClick={() => handleOptionClick(show, item)}>
												{item}
											</button>
										))}
								</div>
							)}
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default Dropdown;
