import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './components/app/app';

const root = createRoot(document.getElementById('app'));

root.render(
	<Auth0Provider
		domain="dev-3cez51jgodwfvuqo.us.auth0.com"
		clientId="FFup0y2U6K4nL1mtviS0kQ0LU1lzt20a"
		authorizationParams={{
			redirect_uri: window.location.origin
		}}
	>
		<App />
	</Auth0Provider>,
);