import { useSerialIds } from 'highcharts';
import { formatLabel, getRandomHashColor } from '../util/utils';

class DataService {
	constructor() { }

	getCheckDates(from, to) {
		var date = [];
		date[0] = new Date(from * 1000);
		date[1] = new Date(to * 1000);
		const differenceMs = Math.abs(date[0] - date[1]);

		// Convert milliseconds to days
		const daysDifference = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

		let checkDates = [];

		for (let i = 0; i < daysDifference; i++) {
			const nextDate = new Date(date[0]);
			nextDate.setDate(date[0].getDate() + i);
			checkDates.push(nextDate);
		}

		return checkDates;
	}

	getLabels(checkDates, from, to) {
		let labels = [];
		for (let j = 0; j < checkDates.length; j++) {
			for (let i = from; i <= to; i++) {
				labels.push(i + ':00');
			}
		}
		return labels;
	}

	getLineDataSets(apps, sessionData, checkDates, from, to, target = null, interactiveDataModifier = null, hourTimeModifier = null, data = null) {
		let lineDataSets = {};
		for (let appIndex = 0; appIndex < apps.length; appIndex++) {
			let interactive = apps[appIndex];
			lineDataSets[interactive.title] = [];
			let interactiveData = sessionData[interactive.title];

			interactiveDataModifier && interactiveDataModifier(interactiveData, interactive, data);

			for (let dateIndex = 0; dateIndex < checkDates.length; dateIndex++) {
				let checkDate = checkDates[dateIndex];

				for (let i = from; i <= to; i++) {
					let timeKey = checkDate.valueOf() + i * 3600000;

					if (interactiveData == null) {
						lineDataSets[interactive.title].push(0);
					} else if (timeKey.toString() in interactiveData) {
						if (target) {
							lineDataSets[interactive.title].push(interactiveData[timeKey][target]);
						} else {
							let hourTime = 0;
							let sessionValues = Object.values(interactiveData[timeKey]['sessions']);

							if (hourTimeModifier) {
								hourTime = hourTimeModifier(sessionValues, interactive.title);
							}

							lineDataSets[interactive.title].push(hourTime);
						}
					} else {
						lineDataSets[interactive.title].push(0);
					}
				}
			}
		}
		return lineDataSets;
	}

	getAppUsersByTimeRange(apps, data, hourRange, timeRange) {
		let checkDates = this.getCheckDates(timeRange[0], timeRange[1]);

		let appUsersByTimeRange = this.getLineDataSets(apps, data['Interactive Sessions'], checkDates, hourRange[0], hourRange[1], null, this.interactiveDataModifier, this.hourTimeModifier, data);

		return appUsersByTimeRange;
	}

	modifyAttentionTotalByApp(barDataSets, sessionValues, interactiveTitle) {
		for (let valueIndex = 0; valueIndex < sessionValues.length; valueIndex++) {
			let value = sessionValues[valueIndex];
			barDataSets[interactiveTitle] += value.attention.count == 0 ? 1 : value.attention.count;
		}
	}

	modifyAttentionTotalByEvents(barDataSets, sessionValues, interactiveTitle) {
		const durationException = {
			'IME Theater': 160,
		};

		for (let valueIndex = 0; valueIndex < sessionValues.length; valueIndex++) {
			let value = sessionValues[valueIndex];
			let entityCount = 1;
			let eventCount = 0;

			let entries = Object.entries(value['events']);
			for (let entryIndex = 0; entryIndex < entries.length; entryIndex++) {
				let [key, eventVal] = entries[entryIndex];
				entityCount += Math.max(eventVal.entities.length, 1);
				eventCount += 1;
			}

			barDataSets[interactiveTitle] += (entityCount / eventCount) * Math.max(value.duration, interactiveTitle in durationException ? durationException[interactiveTitle] : 30);
		}
	}

	unitModifier(barDataSets, interactiveTitle) {
		barDataSets[interactiveTitle] /= 60;
	}

	interactiveDataModifier(interactiveData, interactive, data) {
		if (interactive == 'IME Theater') {
			interactiveData = data['Zone Sessions']['U1 Tunnel'];
		}
	}

	hourTimeModifier(sessionValues) {
		let hourTime = 0;
		for (let valueIndex = 0; valueIndex < sessionValues.length; valueIndex++) {
			let value = sessionValues[valueIndex];
			hourTime += value.attention.count == 0 ? 1 : value.attention.count;
		}
		return hourTime;
	}

	getBarDataSets(apps, sessionData, checkDates, from, to, target = null, interactiveDataModifier = null, modifyAttentionTotalByApp = null, data = null, unitModifier = null) {
		let barDataSets = {};
		for (let appIndex = 0; appIndex < apps.length; appIndex++) {
			let interactive = apps[appIndex];
			barDataSets[interactive.title] = 0;
			let interactiveData = sessionData[interactive.title];

			interactiveDataModifier && interactiveDataModifier(interactiveData, interactive, data);

			for (let dateIndex = 0; dateIndex < checkDates.length; dateIndex++) {
				let checkDate = checkDates[dateIndex];

				for (let i = from; i <= to; i++) {
					let timeKey = (checkDate.valueOf() + i * 3600) * 1000;

					if (interactiveData == null) {
						continue;
					} else if (timeKey.toString() in interactiveData) {
						if (target) {
							barDataSets[interactive.title] += interactiveData[timeKey][target];
						} else {
							let sessionValues = Object.values(interactiveData[timeKey]['sessions']);
							modifyAttentionTotalByApp && modifyAttentionTotalByApp(barDataSets, sessionValues, interactive.title);
						}
					}
				}
			}
			unitModifier && unitModifier(barDataSets, interactive.title);
		}
		return barDataSets;
	}

	getLineChartData(labels, apps, lineDataSets) {
		return {
			labels,
			datasets: apps.map((app) => {
				// faker.seed(app.id)
				let color = getRandomHashColor(app.title);
				// faker.seed(app.id + 2);
				return {
					label: app.title,
					data: lineDataSets[app.title],
					borderColor: color,
					backgroundColor: color,
				};
			}),
		};
	}

	getBarChartData(barDataSets) {
		var barLabels = Object.keys(barDataSets);

		barLabels.sort((a, b) => {
			if (barDataSets[a] > barDataSets[b]) {
				return -1;
			} else {
				return 1;
			}
		});

		let barChartData = barLabels.map((label) => parseInt(barDataSets[label]));

		barLabels = barLabels.map((label) => formatLabel(label));

		return { barLabels, barChartData };
	}

	getCSVforLineChart(apps, labels, lineDataSets, fixedNum = 0) {
		let csvData = 'Hour,' + apps.map((app) => app.title).join(',') + '\n';
		for (let i = 0; i < labels.length; i++) {
			csvData += labels[i];
			for (let appTitle in lineDataSets) {
				csvData += ',' + lineDataSets[appTitle][i].toFixed(fixedNum);
			}
			csvData += '\n';
		}
		return csvData;
	}

	getCSVforBarChart(barDataSets) {
		let csvData = '';
		for (let bar in barDataSets) {
			csvData += bar + ',' + Math.round(barDataSets[bar]) + '\n';
		}
		return csvData;
	}

	getCSVforZones(data) {
		let csvData = 'Totals,Total Users,Engagements,Attention Minutes,MinutesPerUser,Total Visitors\n';
		csvData += ',' + data.total.totalUsers + ',' + data.total.totalEngagements + ',' + data.total.totalAttentionMinutes + ',' + data.total.averageAttention + ',' + data.total.totalVisitors + '\n';
		csvData += '\n';

		csvData += 'Zone,Total Users,Engagements,Attention Minutes,MinutesPerUser\n';
		for (let zone in data.zones.totalUsers) {
			csvData += zone + ',' + data.zones.totalUsers[zone] + ',' + data.zones.totalEngagements[zone] + ',' + data.zones.totalAttentionMinutes[zone] + ',' + data.zones.averageAttention[zone] + '\n';
		}

		csvData += '\n';
		csvData += 'Hour,Total Users,Engagements,Attention Minutes,MinutesPerUser\n';
		for (let hour in data.hourly.totalUsers) {
			csvData += hour + ',' + data.hourly.totalUsers[hour] + ',' + data.hourly.totalEngagements[hour] + ',' + data.hourly.totalAttentionMinutes[hour] + ',' + data.hourly.averageAttention[hour] + '\n';
		}

		return csvData;
	}

	// entityTracking calculation
	calculateMaxSnapshotAndEstimatedTotal(zoneSamples, lastDay) {
		let array2D = Object.values(zoneSamples).map((value) => value);
		let maxSum = 0;
		let columnIndex = 0;
		let estimatedTotal = 0;
		let maxSnapshot = {};

		for (let j = 0; j < array2D[0].length; j++) {
			let { sum, _estimatedTotal } = this.calculateSumForColumn(array2D, j);
			estimatedTotal += _estimatedTotal;
			if (sum > maxSum) {
				maxSum = sum;
				columnIndex = j;
			}
		}

		maxSnapshot.count = maxSum;
		maxSnapshot.time = lastDay[0] + columnIndex * 60;
		estimatedTotal = parseInt(estimatedTotal / 15);

		return { maxSnapshot, estimatedTotal };
	}

	calculateSumForColumn(array2D, columnIndex) {
		let sum = 0;
		let _estimatedTotal = 0;
		for (let i = 0; i < array2D.length; i++) {
			if (!isNaN(array2D[i][columnIndex])) {
				sum += array2D[i][columnIndex];
				_estimatedTotal += array2D[i][columnIndex];
			}
		}
		return { sum, _estimatedTotal };
	}

	/**
	 * Total distinct users in a show
	 * @param {*} totaldistinctZoneVisitors
	 * @returns
	 */
	getDistinctUsersByShow(totaldistinctZoneVisitors) {
		let distinctVisitorsPerShow = 0;
		for (let zone in totaldistinctZoneVisitors) {
			let distinctVisitorsByZone = totaldistinctZoneVisitors[zone];
			if (distinctVisitorsByZone > distinctVisitorsPerShow) {
				distinctVisitorsPerShow = distinctVisitorsByZone;
			}
		}

		return Math.round(distinctVisitorsPerShow);
	}

	/**
	 * 
	 * @param {*} totaldistinctZoneVisitors 
	 * @returns 
	 */
	getVisitorsByShow(zones, sessionData, hourRange, timeRange) {
		let zoneUserTotals = {};
		let checkDates = this.getCheckDates(timeRange[0], timeRange[1]);

		sessionData = sessionData['Zone Sessions'];

		for (let zoneIndex = 0; zoneIndex < zones.length; zoneIndex++) {
			let zone = zones[zoneIndex];
			zoneUserTotals[zone.title] = 0;
			let zoneData = sessionData[zone.title];
			zoneUserTotals[zone.title] = this.calculateUsersForZone(hourRange, checkDates, zoneData);
		}


		let distinctVisitorsPerShow = 0;
		if ("Entry" in zoneUserTotals) {
			distinctVisitorsPerShow = zoneUserTotals["Entry"];
		}
		else {
			for (let zone in zoneUserTotals) {
				let distinctVisitorsByZone = zoneUserTotals[zone];
				if (distinctVisitorsByZone > distinctVisitorsPerShow) {
					distinctVisitorsPerShow = distinctVisitorsByZone;
				}
			}
		}

		return Math.round(distinctVisitorsPerShow);
	}

	/**
	 * Total distinct users in a show by hour
	 * @param {*} totalDistinctZoneVisitors
	 * @returns
	 */
	getDistinctUsersByShowByHour(totalDistinctZoneVisitors, totalDistinctZoneVisitorsByHour) {
		let distinctVisitorsPerShow = 0;
		let maxZone = '';
		for (let zone in totalDistinctZoneVisitors) {
			if (zone.title == 'Entry' || zone.title == "Welcome Desk") { continue; }
			let distinctVisitorsByZone = totalDistinctZoneVisitors[zone];
			if (distinctVisitorsByZone > distinctVisitorsPerShow) {
				distinctVisitorsPerShow = distinctVisitorsByZone;
				maxZone = zone;
			}
		}

		return totalDistinctZoneVisitorsByHour[maxZone];
	}

	/**
	 * 
	 * @param {*} zones 
	 * @param {*} sessionData 
	 * @param {*} hourRange 
	 * @param {*} timeRange 
	 * @returns 
	 */
	getVisitorsByShowByHour(zones, sessionData, hourRange, timeRange) {
		let zoneUserTotals = {};
		let zoneUserHourly = {};
		let checkDates = this.getCheckDates(timeRange[0], timeRange[1]);

		sessionData = sessionData['Zone Sessions'];

		for (let zoneIndex = 0; zoneIndex < zones.length; zoneIndex++) {
			let zone = zones[zoneIndex];
			zoneUserTotals[zone.title] = 0;
			let zoneData = sessionData[zone.title];
			zoneUserTotals[zone.title] = this.calculateUsersForZone(hourRange, checkDates, zoneData);
			zoneUserHourly[zone.title] = this.calculateUsersForZoneByHour(hourRange, checkDates, zoneData);
		}


		let hourlyVisitors = {};
		if ("Entry" in zoneUserHourly) {
			hourlyVisitors = zoneUserHourly["Entry"];
		}
		else {
			let mostVisitors = 0;
			for (let zone in zoneUserTotals) {
				let distinctVisitorsByZone = zoneUserTotals[zone];
				if (distinctVisitorsByZone > mostVisitors) {
					mostVisitors = distinctVisitorsByZone;
					hourlyVisitors = zoneUserHourly[zone];
				}
			}
		}

		return hourlyVisitors;
	}

	/**
	 * Returns the average attention minutes per user for each app
	 * @param {string[]} apps
	 * @param {obj} sessionData
	 * @param {number[]} hourRange
	 * @param {number[]} timeRange
	 * @returns
	 */
	getAppTimePerUser(apps, sessionData, hourRange, timeRange) {
		let appUserTotals = {};
		let appAttentionAverage = {};
		let checkDates = this.getCheckDates(timeRange[0], timeRange[1]);

		sessionData = sessionData['Interactive Sessions'];

		for (let appIndex = 0; appIndex < apps.length; appIndex++) {
			let app = apps[appIndex];
			appUserTotals[app.title] = 0;
			let appData = sessionData[app.title];
			appUserTotals[app.title] = this.calculateUsersForApp(hourRange, checkDates, appData);

			appAttentionAverage[app.title] = this.calculateAttentionMinutesForApp(hourRange, checkDates, appData);

			appAttentionAverage[app.title] /= appUserTotals[app.title];

			appAttentionAverage[app.title] = Math.round(appAttentionAverage[app.title] * 100) / 100;

			if (isNaN(appAttentionAverage[app.title])) {
				appAttentionAverage[app.title] = 0;
			}
		}

		return appAttentionAverage;
	}

	/**
	 * Returns the average attention minutes per user for each app
	 * @param {string[]} apps
	 * @param {obj} sessionData
	 * @param {number[]} hourRange
	 * @param {number[]} timeRange
	 * @returns
	 */
	getAppTimePerUserByHour(apps, sessionData, hourRange, timeRange) {
		let appUserTotals = {};
		let appAttentionAverage = {};
		let checkDates = this.getCheckDates(timeRange[0], timeRange[1]);

		sessionData = sessionData['Interactive Sessions'];

		for (let appIndex = 0; appIndex < apps.length; appIndex++) {
			let app = apps[appIndex];
			appUserTotals[app.title] = 0;
			let appData = sessionData[app.title];
			appUserTotals[app.title] = this.calculateUsersForAppByHour(hourRange, checkDates, appData);

			appAttentionAverage[app.title] = this.calculateAttentionMinutesForAppByHour(hourRange, checkDates, appData);

			for (let hour in appUserTotals[app.title]) {
				appAttentionAverage[app.title][hour] /= appUserTotals[app.title][hour];
				if (isNaN(appAttentionAverage[app.title][hour])) {
					appAttentionAverage[app.title][hour] = 0;
				}
			}
		}

		return appAttentionAverage;
	}

	/**
	 * Returns the average attention minutes per user for each zone
	 * @param {string[]} zones
	 * @param {obj} sessionData
	 * @param {number[]} hourRange
	 * @param {number[]} timeRange
	 * @returns
	 */
	getZoneTimePerUserByHour(zones, sessionData, hourRange, timeRange) {
		let zoneUserTotals = {};
		let zoneAttentionAverage = {};
		let checkDates = this.getCheckDates(timeRange[0], timeRange[1]);

		sessionData = sessionData['Zone Sessions'];

		for (let zoneIndex = 0; zoneIndex < zones.length; zoneIndex++) {
			let zone = zones[zoneIndex];
			if (zone.title == 'Entry' || zone.title == "Welcome Desk") { continue; }
			zoneUserTotals[zone.title] = 0;
			let zoneData = sessionData[zone.title];
			zoneUserTotals[zone.title] = this.calculateUsersForZoneByHour(hourRange, checkDates, zoneData);

			zoneAttentionAverage[zone.title] = this.calculateTotalTimeForZoneByHour(hourRange, checkDates, zoneData);

			for (let hour in zoneUserTotals[zone.title]) {
				zoneAttentionAverage[zone.title][hour] /= zoneUserTotals[zone.title][hour];
				if (isNaN(zoneAttentionAverage[zone.title][hour])) {
					zoneAttentionAverage[zone.title][hour] = 0;
				}
			}
		}

		return zoneAttentionAverage;
	}

	/**
	 * Gets the average time spent in the show per user
	 * @param {string[]} zones
	 * @param {obj} sessionData
	 * @param {number[]} hourRange
	 * @param {number[]} timeRange
	 * @returns
	 */
	getShowTimePerUser(zones, sessionData, hourRange, timeRange) {
		let zoneUserTotals = {};
		let zoneTotalTime = 0;
		let checkDates = this.getCheckDates(timeRange[0], timeRange[1]);
		let totalVisitors = this.getVisitorsByShow(zones, sessionData, hourRange, timeRange);
		sessionData = sessionData['Zone Sessions'];

		for (let zoneIndex = 0; zoneIndex < zones.length; zoneIndex++) {
			let zone = zones[zoneIndex];
			//if (zone.title == 'Entry' || zone.title == "Welcome Desk") { continue; }
			zoneUserTotals[zone.title] = 0;
			let zoneData = sessionData[zone.title];
			zoneUserTotals[zone.title] = this.calculateUsersForZone(hourRange, checkDates, zoneData);

			zoneTotalTime += this.calculateTotalTimeForZone(hourRange, checkDates, zoneData);
		}

		return Math.ceil(zoneTotalTime / totalVisitors);
	}

	/**
	 * Gets the average time spent in the show per user
	 * @param {string[]} zones
	 * @param {obj} sessionData
	 * @param {number[]} hourRange
	 * @param {number[]} timeRange
	 * @returns
	 */
	getShowTimePerUserByHour(zones, sessionData, hourRange, timeRange) {
		let zoneUserTotals = {};
		let zoneUsersByHour = {};
		let zoneHourlyTime = {};
		let hourlyTotalTime = {};

		let checkDates = this.getCheckDates(timeRange[0], timeRange[1]);

		let zoneSessions = sessionData['Zone Sessions'];

		for (let zoneIndex = 0; zoneIndex < zones.length; zoneIndex++) {
			let zone = zones[zoneIndex];
			//if (zone.title == 'Entry' || zone.title == "Welcome Desk") { continue; }
			let zoneData = zoneSessions[zone.title];
			zoneUsersByHour[zone.title] = this.calculateUsersForZoneByHour(hourRange, checkDates, zoneData);
			zoneUserTotals[zone.title] = this.calculateUsersForZone(hourRange, checkDates, zoneData);
			zoneHourlyTime[zone.title] = this.calculateTotalTimeForZoneByHour(hourRange, checkDates, zoneData);

			for (let hour in zoneUsersByHour[zone.title]) {
				hourlyTotalTime[hour] = hourlyTotalTime[hour] ? hourlyTotalTime[hour] + zoneHourlyTime[zone.title][hour] : zoneHourlyTime[zone.title][hour];
			}
		}
		let hourlyUsers = this.getVisitorsByShowByHour(zones, sessionData, hourRange, timeRange);

		for (let hour in hourlyUsers) {
			hourlyTotalTime[hour] /= hourlyUsers[hour];
			hourlyTotalTime[hour] = Math.round(hourlyTotalTime[hour]);
		}

		return hourlyTotalTime;
	}

	/**
	 * Returns the average attention minutes per user for each app
	 * @param {string[]} apps
	 * @param {obj} sessionData
	 * @param {number[]} hourRange
	 * @param {number[]} timeRange
	 * @returns
	 */
	getAppTimeTotals(apps, sessionData, hourRange, timeRange) {
		let appAttention = {};
		let checkDates = this.getCheckDates(timeRange[0], timeRange[1]);

		sessionData = sessionData['Interactive Sessions'];

		for (let appIndex = 0; appIndex < apps.length; appIndex++) {
			let app = apps[appIndex];
			appAttention[app.title] = 0;
			let appData = sessionData[app.title];

			appAttention[app.title] = this.calculateAttentionMinutesForApp(hourRange, checkDates, appData);

			appAttention[app.title];
		}

		return appAttention;
	}

	/**
	 * Returns the average attention minutes per user for each app
	 * @param {string[]} apps
	 * @param {obj} sessionData
	 * @param {number[]} hourRange
	 * @param {number[]} timeRange
	 * @returns
	 */
	getAppTimeTotalsByHour(apps, sessionData, hourRange, timeRange) {
		let appAttention = {};
		let checkDates = this.getCheckDates(timeRange[0], timeRange[1]);

		sessionData = sessionData['Interactive Sessions'];

		for (let appIndex = 0; appIndex < apps.length; appIndex++) {
			let app = apps[appIndex];
			appAttention[app.title] = 0;
			let appData = sessionData[app.title];

			appAttention[app.title] = this.calculateAttentionMinutesForAppByHour(hourRange, checkDates, appData);

			appAttention[app.title];
		}

		return appAttention;
	}

	/**
	 *
	 * @param {string[]} apps
	 * @param {obj} sessionData
	 * @param {number[]} hourRange
	 * @param {number[]} timeRange
	 * @returns
	 */
	getDistinctUsersByApp(apps, sessionData, hourRange, timeRange) {
		let appUserTotals = {};
		let checkDates = this.getCheckDates(timeRange[0], timeRange[1]);

		sessionData = sessionData['Interactive Sessions'];

		for (let appIndex = 0; appIndex < apps.length; appIndex++) {
			let app = apps[appIndex];
			appUserTotals[app.title] = 0;
			let appData = sessionData[app.title];
			appUserTotals[app.title] = this.calculateUsersForApp(hourRange, checkDates, appData);
		}

		return appUserTotals;
	}

	/**
	 *
	 * @param {string[]} apps
	 * @param {obj} sessionData
	 * @param {number[]} hourRange
	 * @param {number[]} timeRange
	 * @returns
	 */
	getDistinctUsersByAppByHour(apps, sessionData, hourRange, timeRange) {
		let appUserTotals = {};
		let checkDates = this.getCheckDates(timeRange[0], timeRange[1]);

		sessionData = sessionData['Interactive Sessions'];

		for (let appIndex = 0; appIndex < apps.length; appIndex++) {
			let app = apps[appIndex];
			appUserTotals[app.title] = {};
			let appData = sessionData[app.title];
			appUserTotals[app.title] = this.calculateUsersForAppByHour(hourRange, checkDates, appData);
		}

		return appUserTotals;
	}

	/**
	 * Gets the total number of distinct users in each zone
	 * @param {string[]} zones
	 * @param {obj} sessionData
	 * @param {number[]} hourRange
	 * @param {number[]} timeRange
	 * @returns zone dictionary with key of zone name and value of total distinct users
	 */
	getDistinctUsersByZone(zones, sessionData, hourRange, timeRange) {
		let zoneUserTotals = {};
		let checkDates = this.getCheckDates(timeRange[0], timeRange[1]);

		sessionData = sessionData['Zone Sessions'];

		for (let zoneIndex = 0; zoneIndex < zones.length; zoneIndex++) {
			let zone = zones[zoneIndex];
			if (zone.title == 'Entry' || zone.title == "Welcome Desk") { continue; }
			zoneUserTotals[zone.title] = 0;
			let zoneData = sessionData[zone.title];
			zoneUserTotals[zone.title] = this.calculateUsersForZone(hourRange, checkDates, zoneData);
		}

		return zoneUserTotals;
	}

	/**
	 *
	 * @param {string[]} zones
	 * @param {obj} sessionData
	 * @param {number[]} hourRange
	 * @param {number[]} timeRange
	 * @returns
	 */
	getDistinctUsersByZoneByHour(zones, sessionData, hourRange, timeRange) {
		let zoneUserTotals = {};
		let checkDates = this.getCheckDates(timeRange[0], timeRange[1]);

		sessionData = sessionData['Zone Sessions'];

		for (let zoneIndex = 0; zoneIndex < zones.length; zoneIndex++) {
			let zone = zones[zoneIndex];
			if (zone.title == 'Entry' || zone.title == "Welcome Desk") { continue; }
			zoneUserTotals[zone.title] = {};
			let zoneData = sessionData[zone.title];
			zoneUserTotals[zone.title] = this.calculateUsersForZoneByHour(hourRange, checkDates, zoneData);
		}

		return zoneUserTotals;
	}

	/**
	 * Calculates the total time spent in each zone, in minutes
	 * @param {string[]} zones
	 * @param {obj} sessionData
	 * @param {number[]} hourRange
	 * @param {number[]} timeRange
	 * @returns {obj} zone dictionary with key of zone name and value of total time spent in minutes AKA Attention Minutes
	 */
	getZoneTimeTotals(zones, sessionData, hourRange, timeRange) {
		let zoneTimeTotals = {};
		let checkDates = this.getCheckDates(timeRange[0], timeRange[1]);

		sessionData = sessionData['Zone Sessions'];

		for (let zoneIndex = 0; zoneIndex < zones.length; zoneIndex++) {
			let zone = zones[zoneIndex];
			if (zone.title == 'Entry' || zone.title == "Welcome Desk") { continue; }
			zoneTimeTotals[zone.title] = 0;
			let zoneData = sessionData[zone.title];
			zoneTimeTotals[zone.title] = this.calculateTotalTimeForZone(hourRange, checkDates, zoneData);
		}

		return zoneTimeTotals;
	}

	/**
	 * Calculates the total time spent in each zone, in minutes
	 * @param {string[]} zones
	 * @param {obj} sessionData
	 * @param {number[]} hourRange
	 * @param {number[]} timeRange
	 * @returns {obj} zone dictionary with key of zone name and value of total time spent in minutes AKA Attention Minutes
	 */
	getZoneTimeTotalsByHour(zones, sessionData, hourRange, timeRange) {
		let zoneTimeTotals = {};
		let checkDates = this.getCheckDates(timeRange[0], timeRange[1]);

		sessionData = sessionData['Zone Sessions'];

		for (let zoneIndex = 0; zoneIndex < zones.length; zoneIndex++) {
			let zone = zones[zoneIndex];
			if (zone.title == 'Entry' || zone.title == "Welcome Desk") { continue; }
			zoneTimeTotals[zone.title] = 0;
			let zoneData = sessionData[zone.title];
			zoneTimeTotals[zone.title] = this.calculateTotalTimeForZoneByHour(hourRange, checkDates, zoneData);
		}

		return zoneTimeTotals;
	}

	/**
	 * Calculates the total amount of attention minutes users spend at a particular show
	 * @param {obj} appAttentionMinutes total attention minutes per app
	 * @returns {number} the sum of the averages divided by the highest value rounded to nearest whole number
	 */
	getTotalAttentionMinutesPerShow(appAttentionMinutes) {
		let totalAttentionMinutes = 0;
		for (let app in appAttentionMinutes) {
			totalAttentionMinutes += appAttentionMinutes[app];
		}

		return totalAttentionMinutes;
	}

	/**
	 * Calculates the total amount of attention minutes users spend at a particular show
	 * @param {obj} appAttentionMinutes calculated above in getZoneTimeTotals
	 * @returns {number} the sum of the averages divided by the highest value rounded to nearest whole number
	 */
	getTotalAttentionMinutesPerShowByHour(appAttentionMinutes) {
		let totalHourlyAttentionMinutes = {};

		for (let app in appAttentionMinutes) {
			for (let hour in appAttentionMinutes[app]) {
				totalHourlyAttentionMinutes[hour] = totalHourlyAttentionMinutes[hour] ? totalHourlyAttentionMinutes[hour] + appAttentionMinutes[app][hour] : appAttentionMinutes[app][hour];
			}
		}

		return totalHourlyAttentionMinutes;
	}

	/**
	 * Calculates the average time spent in each zone per user, in seconds
	 * @param {string[]} zones
	 * @param {obj} sessionData
	 * @param {number[]} hourRange
	 * @param {number[]} timeRange
	 * @returns {obj} zone dictionary with key of zone name and value of average time spent in seconds
	 */
	getZoneTimePerUser(zones, sessionData, hourRange, timeRange) {
		let zoneUserTotals = {};
		let zoneAttentionAverage = {};
		let checkDates = this.getCheckDates(timeRange[0], timeRange[1]);

		sessionData = sessionData['Zone Sessions'];

		for (let zoneIndex = 0; zoneIndex < zones.length; zoneIndex++) {
			let zone = zones[zoneIndex];
			zoneUserTotals[zone.title] = 0;
			let zoneData = sessionData[zone.title];
			if (zone.title == 'Entry' || zone.title == "Welcome Desk") { continue; }
			// user count a unique user is zoneData[timeKey]
			zoneUserTotals[zone.title] = this.calculateUsersForZone(hourRange, checkDates, zoneData);

			zoneAttentionAverage[zone.title] = this.calculateTotalTimeForZone(hourRange, checkDates, zoneData);

			zoneAttentionAverage[zone.title] /= zoneUserTotals[zone.title];

			zoneAttentionAverage[zone.title] = Math.round(zoneAttentionAverage[zone.title] * 100) / 100;

			if (isNaN(zoneAttentionAverage[zone.title])) {
				zoneAttentionAverage[zone.title] = 0;
			}
		}

		return zoneAttentionAverage;
	}

	/**
	 * Returns the average attention minutes per user for each app
	 * @param {string[]} apps
	 * @param {obj} sessionData
	 * @param {number[]} hourRange
	 * @param {number[]} timeRange
	 * @returns
	 */
	getEngagementsByApp(apps, sessionData, hourRange, timeRange) {
		let appEngagement = {};
		let checkDates = this.getCheckDates(timeRange[0], timeRange[1]);

		sessionData = sessionData['Interactive Sessions'];

		for (let appIndex = 0; appIndex < apps.length; appIndex++) {
			let app = apps[appIndex];
			appEngagement[app.title] = 0;
			let appData = sessionData[app.title];

			appEngagement[app.title] = this.calculateEngagementsForApp(hourRange, checkDates, appData);

			appEngagement[app.title];
		}

		return appEngagement;
	}

	/**
	 * Returns the average attention minutes per user for each app
	 * @param {string[]} apps
	 * @param {obj} sessionData
	 * @param {number[]} hourRange
	 * @param {number[]} timeRange
	 * @returns
	 */
	getEngagementsByAppByHour(apps, sessionData, hourRange, timeRange) {
		let appEngagement = {};
		let checkDates = this.getCheckDates(timeRange[0], timeRange[1]);

		sessionData = sessionData['Interactive Sessions'];

		for (let appIndex = 0; appIndex < apps.length; appIndex++) {
			let app = apps[appIndex];
			appEngagement[app.title] = 0;
			let appData = sessionData[app.title];

			appEngagement[app.title] = this.calculateEngagementsForAppByHour(hourRange, checkDates, appData);

			appEngagement[app.title];
		}

		return appEngagement;
	}

	/**
	 * Returns the average attention minutes per user for each app
	 * @param {string[]} apps
	 * @param {string[]} apps
	 * @param {obj} sessionData
	 * @param {number[]} hourRange
	 * @param {number[]} timeRange
	 * @param {obj} pairingData
	 * @returns
	 */
	getEngagementsByZone(zones, sessionData, hourRange, timeRange, pairingData) {
		let zoneUserTotals = {};
		let checkDates = this.getCheckDates(timeRange[0], timeRange[1]);

		sessionData = sessionData['Zone Sessions'];

		for (let zoneIndex = 0; zoneIndex < zones.length; zoneIndex++) {
			let zone = zones[zoneIndex];
			if (zone.title == 'Entry' || zone.title == "Welcome Desk") { continue; }
			zoneUserTotals[zone.title] = 0;
			let zoneData = sessionData[zone.title];
			zoneUserTotals[zone.title] = this.calculateEngagementsForZone(hourRange, checkDates, zoneData);
		}

		return zoneUserTotals;
	}

	/**
	 * Returns the average attention minutes per user for each app
	 * @param {string[]} apps
	 * @param {string[]} apps
	 * @param {obj} sessionData
	 * @param {number[]} hourRange
	 * @param {number[]} timeRange
	 * @param {obj} pairingData
	 * @returns
	 */
	getEngagementsByZoneByHour(zones, sessionData, hourRange, timeRange, pairingData) {
		let zoneUserTotals = {};
		let checkDates = this.getCheckDates(timeRange[0], timeRange[1]);

		sessionData = sessionData['Zone Sessions'];

		for (let zoneIndex = 0; zoneIndex < zones.length; zoneIndex++) {
			let zone = zones[zoneIndex];
			if (zone.title == 'Entry' || zone.title == "Welcome Desk") { continue; }
			zoneUserTotals[zone.title] = {};
			let zoneData = sessionData[zone.title];
			zoneUserTotals[zone.title] = this.calculateEngagementsForZoneByHour(hourRange, checkDates, zoneData);
		}

		return zoneUserTotals;
	}

	/**
	 * Returns the total engagements in the show
	 * @param {obj} appEngagement
	 * @returns
	 */
	getEngagementsByShow(appEngagement) {
		let totalEngagements = 0;
		for (let app in appEngagement) {
			totalEngagements += appEngagement[app];
		}

		return totalEngagements;
	}

	/**
	 * Returns the total engagements in the show by hour
	 * @param {obj} appEngagement
	 * @returns
	 */
	getEngagementsByShowByHour(appEngagement) {
		let totalEngagements = {};
		for (let app in appEngagement) {
			for (let hour in appEngagement[app]) {
				if (!totalEngagements[hour]) {
					totalEngagements[hour] = 0;
				}
				totalEngagements[hour] += appEngagement[app][hour];
			}
		}

		return totalEngagements;
	}

	/**
	 * Generates pathing information for each user's journey through zones during each hour.
	 * The pathing starts with "entrance" and ends with "exit" if present, otherwise includes all transitions.
	 * @param {Object} userInfo - Object containing user information for each show hour.
	 * @returns {Object} Object where each key is an hour and value is an array of path segments.
	 */

	// {
	//   "totalPathsTaken": {fromZone: toZone, fromZone: toZone},
	//   "numZonesVisited" : { fromZone: 1} // this gets incremented every time we visit a zone
	//   "hourlyPathing" : { hourOfShow :
	//     {
	//       "totalPathsTaken": {fromZone: toZone},
	//       "numZonesVisited": {fromZone: 0},
	//     }
	// }
	getZonePathing(userInfo) {
		let pathwaysTakenPerShow = {};
		let countNumZonesVisitedPerShowExcludingEnterExit = 0;
		let zonesVisitedPerShow = {}; // Including 'entrance' and 'exit'
		const hourlyPathInfo = {};

		// Iterate over each hour of userInfo
		for (const showHour in userInfo) {
			if (userInfo.hasOwnProperty(showHour)) {
				// Initialize hourlyPathInfo for the current hour
				hourlyPathInfo[showHour] = {
					numZonesExcludingEnterExit: 0,
					zonesVisited: {},
					pathways: {},
				};

				// Iterate over each unique user in the current hour
				for (const entityId in userInfo[showHour].uniqueUsersPerShowHour) {
					if (userInfo[showHour].uniqueUsersPerShowHour.hasOwnProperty(entityId)) {
						const zonePath = userInfo[showHour].uniqueUsersPerShowHour[entityId].zonePath;

						// Process each segment in the zonePath for the current user
						for (let i = 0; i < zonePath.length; i++) {
							const [fromZone, toZone] = zonePath[i];

							// Track pathways taken per hour
							if (!pathwaysTakenPerShow.hasOwnProperty(fromZone)) {
								pathwaysTakenPerShow[fromZone] = {};
							}
							// count the number of times globally (by show) including the entrance and exit that users went from a path to another
							if (!pathwaysTakenPerShow[fromZone].hasOwnProperty(toZone)) {
								pathwaysTakenPerShow[fromZone][toZone] = 0;
							}
							pathwaysTakenPerShow[fromZone][toZone]++;

							// count the pathways by hour including entrance and exit
							if (!hourlyPathInfo[showHour].pathways.hasOwnProperty(fromZone)) {
								hourlyPathInfo[showHour].pathways[fromZone] = {};
							}
							if (!hourlyPathInfo[showHour].pathways[fromZone].hasOwnProperty(toZone)) {
								hourlyPathInfo[showHour].pathways[fromZone][toZone] = 0;
							}
							hourlyPathInfo[showHour].pathways[fromZone][toZone]++;

							// Increment counts for total zones visited (including 'entrance' and 'exit')
							if (!zonesVisitedPerShow.hasOwnProperty(fromZone)) {
								zonesVisitedPerShow[fromZone] = 0;
							}
							zonesVisitedPerShow[fromZone]++;

							// Increment zonesVisited in hourlyPathInfo
							if (!hourlyPathInfo[showHour].zonesVisited.hasOwnProperty(fromZone)) {
								hourlyPathInfo[showHour].zonesVisited[fromZone] = 0;
							}
							hourlyPathInfo[showHour].zonesVisited[fromZone]++;

							// Check if fromZone is not 'entrance' and toZone is not 'exit' to increment counts
							if (fromZone !== 'entrance' && toZone !== 'exit') {
								countNumZonesVisitedPerShowExcludingEnterExit++;
								hourlyPathInfo[showHour].numZonesExcludingEnterExit++;
							}

							// Check if toZone is 'exit' to increment counts
							if (toZone === 'exit') {
								if (!zonesVisitedPerShow.hasOwnProperty('exit')) {
									zonesVisitedPerShow['exit'] = 0;
								}
								zonesVisitedPerShow['exit']++;

								if (!hourlyPathInfo[showHour].zonesVisited.hasOwnProperty('exit')) {
									hourlyPathInfo[showHour].zonesVisited['exit'] = 0;
								}
								hourlyPathInfo[showHour].zonesVisited['exit']++;
							}
						}
					}
				}
			}
		}

		// Return the aggregated data
		return {
			pathwaysTakenPerShow: pathwaysTakenPerShow,
			countNumZonesVisitedPerShowExcludingEnterExit: countNumZonesVisitedPerShowExcludingEnterExit,
			zonesVisitedPerShow: zonesVisitedPerShow,
			hourlyPathInfo: hourlyPathInfo,
		};
	}

	/**
	 * Retrieves the hourly summary of user zone travel based on provided zones, session data,
	 * hour range, and time range.
	 *
	 * @param {Array} zones - List of zones to consider.
	 * @param {Object} sessionData - Session data containing zone sessions.
	 * @param {Array} hourRange - Range of hours to consider.
	 * @param {Array} timeRange - Range of time to filter sessions.
	 * @returns {Object} Hourly summary object representing zone visits and user info.
	 */
	getHourlySummaryOfUserZoneTravel(zones, sessionData, hourRange, timeRange) {
		sessionData = sessionData['Zone Sessions'];
		let checkDates = this.getCheckDates(timeRange[0], timeRange[1]);

		let usersMappedToZonesByHour = {}; // Initialize or declare with existing data

		for (let zoneIndex = 0; zoneIndex < zones.length; zoneIndex++) {
			let zone = zones[zoneIndex];
			let zoneData = sessionData[zone.title];

			let userInfo = this.calculateZonalUserInfoPerHour(hourRange, checkDates, zoneData, zone.title, usersMappedToZonesByHour);

			usersMappedToZonesByHour = userInfo;
		}

		// After processing all zones, add "exit" to zone paths
		usersMappedToZonesByHour = this.addExitToZonePath(usersMappedToZonesByHour);

		return usersMappedToZonesByHour;
	}

	/**
	 * Calculates the average number of zones visited per user for each hour, along with max and min zones per user or high / low for each hour.
	 * @param {Object} userInfo - Object containing user information.
	 * @returns {Object} Object with current hour as key and [maxUsersAtZone, minUsersAtZone, averageZonesPerUser] as value.
	 */
	getHighLowAvgZonesPerUserByHour(userInfo = {}) {
		const result = {};

		for (const hour in userInfo) {
			if (userInfo.hasOwnProperty(hour)) {
				const hourInfo = userInfo[hour];
				const totalUniqueUsers = Object.keys(hourInfo.uniqueUsersPerShowHour).length;
				const totalZonesVisited = hourInfo.totalZonesVisitedPerHour;
				const maxUsersAtZone = hourInfo.maxUsersAtZone;
				const minUsersAtZone = hourInfo.minUsersAtZone;

				// Calculate average zones per user for the current hour
				let averageZonesPerUser = 0;
				if (totalUniqueUsers > 0) {
					averageZonesPerUser = totalZonesVisited / totalUniqueUsers;
				}

				// Store [max, min, average] in the result object with current hour as key
				result[hour] = [maxUsersAtZone, minUsersAtZone, averageZonesPerUser];
			}
		}

		return result;
	}

	/**
	 * Get pathing info for each user's journey through zones.
	 * @param {Object} data - Object containing the data from the show
	 * @param {Object} mapInfo - Object containing zone information for the show
	 */
	getFromToZoneTraffic(data, mapInfo) {
		const result = {};
		result.links = [];
		result.nodes = [];
		result.lowestValue = 1;

		let userData = data['User Data'];

		for (let id in userData) {
			let zones = userData[id]['zones'];
			for (let i = 0; i < zones.length - 1; i++) {
				let fromZone = zones[i].value;
				let toZone = zones[i + 1].value;

				let compareObject = { from: fromZone, to: toZone };
				let compareObjectReverse = { from: toZone, to: fromZone };

				let found = false;
				for (let j = 0; j < result.links.length; j++) {
					let link = result.links[j];
					if (link.from === compareObject.from && link.to === compareObject.to) {
						link.width++;
						found = true;
						break;
					}
					if (link.from === compareObjectReverse.from && link.to === compareObjectReverse.to) {
						link.width++;
						found = true;
						break;
					}
				}
				if (!found) {
					result.links.push({ from: fromZone, to: toZone, width: 1 });
				}
			}
		}

		for (let zone in mapInfo) {
			let position = this.calculateAveragePoint(mapInfo[zone].scaledPoints);
			result.nodes.push({ id: mapInfo[zone].name, name: mapInfo[zone].name, x: position.x, y: position.y, value: 0 });
		}

		for (let i = 0; i < result.links.length; i++) {
			let link = result.links[i];
			if (link.value < result.lowestValue) {
				result.lowestValue = link.value;
			}
		}

		// Step 1: Find min and max values
		let minValue = Infinity;
		let maxValue = -Infinity;
		for (let link of result.links) {
			result.nodes.find(node => node.name === link.from).value += link.width;
			result.nodes.find(node => node.name === link.to).value += link.width;
			if (link.width < minValue) {
				minValue = link.width;
			}
			if (link.width > maxValue) {
				maxValue = link.width;
			}
		}

		// Step 2: Calculate the range
		let range = maxValue - minValue;

		// Step 3: Normalize values
		for (let link of result.links) {
			link.normalizedValue = (link.width - minValue) / range;
		}

		// Step 1: Find min and max values
		minValue = Infinity;
		maxValue = -Infinity;
		for (let node of result.nodes) {
			if (node.value < minValue) {
				minValue = node.value;
			}
			if (node.value > maxValue) {
				maxValue = node.value;
			}
		}

		// Step 2: Calculate the range
		range = maxValue - minValue;

		// Step 3: Normalize values
		for (let node of result.nodes) {
			node.normalizedValue = (node.value - minValue) / range;
		}

		return result;
	}

	/**
	 * Calculates the average point of a set of points
	 * @param {obj[]} points
	 * @returns {obj} average point
	 */
	calculateAveragePoint(points) {
		let x = 0;
		let y = 0;
		for (let i = 0; i < points.length; i++) {
			x += points[i].x;
			y += points[i].y;
		}
		return { x: x / points.length, y: y / points.length };
	}

	/**
	 * Calculates the total time spent in each zone, returning both the totals and the overall time
	 * @param {string[]} zones
	 * @param {obj} sessionData
	 * @param {number[]} timeRange
	 * @param {number[]} hourRange
	 * @param {number} estimatedStaff
	 * @returns
	 */
	calculateZoneTimeTotalsAndOverall(zones, sessionData, timeRange, hourRange, estimatedStaff) {
		let hours = 0;
		let zoneTimeTotals = {};
		let totalTime = 0;
		let checkDates = this.getCheckDates(timeRange[0], timeRange[1]);

		sessionData = sessionData['Zone Sessions'];

		for (let zoneIndex = 0; zoneIndex < zones.length; zoneIndex++) {
			let zone = zones[zoneIndex];
			zoneTimeTotals[zone.title] = 0;
			let zoneData = sessionData[zone.title];
			zoneTimeTotals[zone.title] = this.calculateTotalTimeForZone(hourRange, checkDates, zoneData);
			totalTime += zoneTimeTotals[zone.title];
		}

		// Total time can be sent back as well, just not doing so here anymore.
		totalTime -= hours * estimatedStaff;

		return { zoneTimeTotals, totalTime };
	}

	/**
	 * Steps through the hours in the range and calculates the total time spent in each zone in minutes
	 * @param {number[]} hourRange
	 * @param {obj[]} checkDates
	 * @param {obj} zoneData
	 * @returns
	 */
	calculateTotalTimeForZone(hourRange, checkDates, zoneData) {
		let _hours = 0;
		let _totalTime = 0;

		for (let dateIndex = 0; dateIndex < checkDates.length; dateIndex++) {
			let checkDate = checkDates[dateIndex];

			for (let i = hourRange[0]; i <= hourRange[1]; i++) {
				let timeKey = checkDate.valueOf();
				timeKey += i * 3600000;
				_hours += 1;
				if (zoneData != null && timeKey.toString() in zoneData) {
					_totalTime += zoneData[timeKey]['totalDuration'];
				}
			}
		}

		_totalTime /= 60;
		return Math.floor(_totalTime);
	}

	/**
	 * Steps through the hours in the range and calculates the total time spent in each zone in minutes
	 * @param {number[]} hourRange
	 * @param {obj[]} checkDates
	 * @param {obj} zoneData
	 * @returns
	 */
	calculateTotalTimeForZoneByHour(hourRange, checkDates, zoneData) {
		let _totalTime = {};

		for (let dateIndex = 0; dateIndex < checkDates.length; dateIndex++) {
			let checkDate = checkDates[dateIndex];

			for (let i = hourRange[0]; i <= hourRange[1]; i++) {
				let timeKey = checkDate.valueOf();
				_totalTime[i] = 0;
				timeKey += i * 3600000;

				if (zoneData != null && timeKey.toString() in zoneData) {
					_totalTime[i] += zoneData[timeKey]['totalDuration'];
				}
				_totalTime[i] = Math.round(_totalTime[i] / 60);
			}
		}

		return _totalTime;
	}

	/**
	 * Steps through the hours in the range and calculates the average time per user in a zone
	 * @param {number[]} hourRange
	 * @param {obj[]} checkDates
	 * @param {obj} zoneData
	 * @returns
	 */
	calculateAverageTimeForZone(hourRange, checkDates, zoneData) {
		let _hours = 0;
		let _totalTime = 0;

		for (let dateIndex = 0; dateIndex < checkDates.length; dateIndex++) {
			let checkDate = checkDates[dateIndex];

			for (let i = hourRange[0]; i <= hourRange[1]; i++) {
				let timeKey = checkDate.valueOf();
				timeKey += i * 3600000;
				_hours += 1;
				if (zoneData != null && timeKey.toString() in zoneData) {
					_totalTime += zoneData[timeKey]['averageDuration'];
				}
			}
		}

		_totalTime /= _hours;
		return Math.floor(_totalTime);
	}

	/**
	 * Steps through the hours in the range and calculates the total time spent in each zone in minutes
	 * @param {number[]} hourRange
	 * @param {obj[]} checkDates
	 * @param {obj} zoneData
	 * @returns
	 */
	calculateAttentionMinutesForApp(hourRange, checkDates, appData) {
		let _totalAttention = 0;

		for (let dateIndex = 0; dateIndex < checkDates.length; dateIndex++) {
			let checkDate = checkDates[dateIndex];

			for (let i = hourRange[0]; i <= hourRange[1]; i++) {
				let timeKey = checkDate.valueOf();
				timeKey += i * 3600000;
				if (appData != null && timeKey.toString() in appData) {
					for (let sessionKey in appData[timeKey].sessions) {

						let value = appData[timeKey].sessions[sessionKey];
						/*
						let entityCount = 1;
						let eventCount = 0;

						let entries = Object.entries(value['events']);
						for (let entryIndex = 0; entryIndex < entries.length; entryIndex++) {
							let [key, eventVal] = entries[entryIndex];
							entityCount += Math.max(eventVal.entities.length, 1);
							eventCount += 1;
						}
						*/
						//_totalAttention += (entityCount / eventCount) * Math.max(value.duration, 30);
						//_totalAttention += value.attention.averageAudience * Math.max(value.duration, 30);
						_totalAttention += value.attention.length;
					}
				}
			}
		}

		return Math.floor(_totalAttention / 60);
	}

	/**
	 * Steps through the hours in the range and calculates the total time spent in each zone in minutes
	 * @param {number[]} hourRange
	 * @param {obj[]} checkDates
	 * @param {obj} zoneData
	 * @returns
	 */
	calculateAttentionMinutesForAppByHour(hourRange, checkDates, appData) {
		let totalAttention = {};

		for (let dateIndex = 0; dateIndex < checkDates.length; dateIndex++) {
			let checkDate = checkDates[dateIndex];

			for (let i = hourRange[0]; i <= hourRange[1]; i++) {
				let timeKey = checkDate.valueOf();
				totalAttention[i] = 0;
				timeKey += i * 3600000;
				if (appData != null && timeKey.toString() in appData) {
					for (let sessionKey in appData[timeKey].sessions) {
						let value = appData[timeKey].sessions[sessionKey];
						let entityCount = 1;
						let eventCount = 0;

						let entries = Object.entries(value['events']);
						for (let entryIndex = 0; entryIndex < entries.length; entryIndex++) {
							let [key, eventVal] = entries[entryIndex];
							entityCount += Math.max(eventVal.entities.length, 1);
							eventCount += 1;
						}

						totalAttention[i] += (entityCount / eventCount) * Math.max(value.duration, 30);
						//_totalAttention +=appData[timeKey].sessions[sessionKey].attention.length;
					}
				}
				totalAttention[i] = Math.floor(totalAttention[i] / 60);
			}
		}

		return totalAttention;
	}

	/**
	 * Steps through the hours in the range and calculates the total time spent in each zone in minutes
	 * @param {number[]} hourRange
	 * @param {obj[]} checkDates
	 * @param {obj} zoneData
	 * @returns
	 */
	calculateEngagementsForApp(hourRange, checkDates, appData) {
		let _totalEngagements = 0;

		for (let dateIndex = 0; dateIndex < checkDates.length; dateIndex++) {
			let checkDate = checkDates[dateIndex];

			for (let i = hourRange[0]; i <= hourRange[1]; i++) {
				let timeKey = checkDate.valueOf();
				timeKey += i * 3600000;
				if (appData != null && timeKey.toString() in appData) {
					for (let sessionKey in appData[timeKey].sessions) {
						let value = appData[timeKey].sessions[sessionKey];
						let entityCount = 1;
						let eventCount = 0;

						let entries = Object.entries(value['events']);
						for (let entryIndex = 0; entryIndex < entries.length; entryIndex++) {
							let [key, eventVal] = entries[entryIndex];
							entityCount += Math.max(eventVal.entities.length, 1);
							eventCount += 1;
						}

						_totalEngagements += entityCount;
						//_totalAttention +=appData[timeKey].sessions[sessionKey].attention.length;
					}
				}
			}
		}

		return _totalEngagements;
	}

	/**
	 * Steps through the hours in the range and calculates the total time spent in each zone in minutes
	 * @param {number[]} hourRange
	 * @param {obj[]} checkDates
	 * @param {obj} zoneData
	 * @returns
	 */
	calculateEngagementsForAppByHour(hourRange, checkDates, appData) {
		let totalEngagement = {};

		for (let dateIndex = 0; dateIndex < checkDates.length; dateIndex++) {
			let checkDate = checkDates[dateIndex];

			for (let i = hourRange[0]; i <= hourRange[1]; i++) {
				let timeKey = checkDate.valueOf();
				totalEngagement[i] = 0;
				timeKey += i * 3600000;
				if (appData != null && timeKey.toString() in appData) {
					for (let sessionKey in appData[timeKey].sessions) {
						let value = appData[timeKey].sessions[sessionKey];
						let entityCount = 1;
						let eventCount = 0;

						let entries = Object.entries(value['events']);
						for (let entryIndex = 0; entryIndex < entries.length; entryIndex++) {
							let [key, eventVal] = entries[entryIndex];
							entityCount += Math.max(eventVal.entities.length, 1);
							eventCount += 1;
						}

						totalEngagement[i] += entityCount;
					}
				}
			}
		}

		return totalEngagement;
	}

	/**
	 * Steps through the hours in the range and calculates the total time spent in each zone in minutes
	 * @param {number[]} hourRange
	 * @param {obj[]} checkDates
	 * @param {obj} zoneData
	 * @returns
	 */
	calculateEngagementsForZone(hourRange, checkDates, zoneData) {
		let _users = 0;

		for (let dateIndex = 0; dateIndex < checkDates.length; dateIndex++) {
			let checkDate = checkDates[dateIndex];

			for (let i = hourRange[0]; i <= hourRange[1]; i++) {
				let timeKey = checkDate.valueOf();
				timeKey += i * 3600000;
				if (zoneData != null && timeKey.toString() in zoneData) {
					let hour = zoneData[timeKey];
					for (let sessionKey in hour.sessions) {
						if (hour.sessions[sessionKey].duration > 30) {
							_users += 1;
						}
					}
				}
			}
		}

		return Math.floor(_users);
	}

	/**
	 * Steps through the hours in the range and calculates the total time spent in each zone in minutes
	 * @param {number[]} hourRange
	 * @param {obj[]} checkDates
	 * @param {obj} zoneData
	 * @returns
	 */
	calculateEngagementsForZoneByHour(hourRange, checkDates, zoneData) {
		let _users = {};

		for (let dateIndex = 0; dateIndex < checkDates.length; dateIndex++) {
			let checkDate = checkDates[dateIndex];

			for (let i = hourRange[0]; i <= hourRange[1]; i++) {
				let timeKey = checkDate.valueOf();
				_users[i] = 0;
				timeKey += i * 3600000;
				if (zoneData != null && timeKey.toString() in zoneData) {
					let hour = zoneData[timeKey];
					for (let sessionKey in hour.sessions) {
						if (hour.sessions[sessionKey].duration > 30) {
							_users[i] += 1;
						}
					}
				}
			}
		}

		return _users;
	}

	/**
	 * Steps through the hours in the range and calculates the total time spent in each zone in minutes
	 * @param {number[]} hourRange
	 * @param {obj[]} checkDates
	 * @param {obj} zoneData
	 * @returns
	 */
	calculateUsersForAppByHour(hourRange, checkDates, appData) {
		let _users = {};

		for (let dateIndex = 0; dateIndex < checkDates.length; dateIndex++) {
			let checkDate = checkDates[dateIndex];

			for (let i = hourRange[0]; i <= hourRange[1]; i++) {
				let timeKey = checkDate.valueOf();
				_users[i] = 0;
				timeKey += i * 3600000;
				if (appData != null && timeKey.toString() in appData) {
					for (let sessionKey in appData[timeKey].sessions) {
						_users[i] += appData[timeKey].sessions[sessionKey].attention.count;
					}
				}
			}
		}

		return _users;
	}

	/**
	 * Steps through the hours in the range and calculates the total time spent in each zone in minutes
	 * @param {number[]} hourRange
	 * @param {obj[]} checkDates
	 * @param {obj} zoneData
	 * @returns
	 */
	calculateUsersForApp(hourRange, checkDates, appData) {
		let _users = 0;

		for (let dateIndex = 0; dateIndex < checkDates.length; dateIndex++) {
			let checkDate = checkDates[dateIndex];

			for (let i = hourRange[0]; i <= hourRange[1]; i++) {
				let timeKey = checkDate.valueOf();
				timeKey += i * 3600000;
				if (appData != null && timeKey.toString() in appData) {
					for (let sessionKey in appData[timeKey].sessions) {
						_users += appData[timeKey].sessions[sessionKey].attention.averageAudience;
					}
				}
			}
		}

		return Math.ceil(_users);
	}

	/**
	 * Steps through the hours in the range and calculates the total time spent in each zone in minutes
	 * @param {number[]} hourRange
	 * @param {obj[]} checkDates
	 * @param {obj} zoneData
	 * @returns
	 */
	calculateUsersForZone(hourRange, checkDates, zoneData) {
		let _users = 0;

		for (let dateIndex = 0; dateIndex < checkDates.length; dateIndex++) {
			let checkDate = checkDates[dateIndex];

			for (let i = hourRange[0]; i <= hourRange[1]; i++) {
				let timeKey = checkDate.valueOf();
				timeKey += i * 3600000;
				if (zoneData != null && timeKey.toString() in zoneData) {
					let hour = zoneData[timeKey];
					for (let sessionKey in hour.sessions) {
						if (hour.sessions[sessionKey].duration > 10) {
							_users += 1;
						}
					}
				}
			}
		}

		return Math.floor(_users * 0.85);
	}

	/**
	 * Steps through the hours in the range and calculates the time spent in each zone per hour in minutes
	 * @param {number[]} hourRange
	 * @param {obj[]} checkDates
	 * @param {obj} zoneData
	 * @returns
	 */
	calculateUsersForZoneByHour(hourRange, checkDates, zoneData) {
		let _users = {};

		for (let dateIndex = 0; dateIndex < checkDates.length; dateIndex++) {
			let checkDate = checkDates[dateIndex];

			for (let i = hourRange[0]; i <= hourRange[1]; i++) {
				let timeKey = checkDate.valueOf();
				_users[i] = 0;
				timeKey += i * 3600000;
				if (zoneData != null && timeKey.toString() in zoneData) {
					let hour = zoneData[timeKey];
					for (let sessionKey in hour.sessions) {
						if (hour.sessions[sessionKey].duration > 10) {
							_users[i] += 1;
						}
					}
				}
			}
		}

		return _users;
	}

	/*
	   { currentShowHour :
		"averageZonesPerUser" : 0
		"maxUsersAtZone": 0
		"totalUniqueUsersPerHour" : 0
		"totalZonesVisitedPerHour" : 0
		"uniqueUsersPerShowHour": {
		  entityId (unique user) :
		  {
			"totalZonesVisited" : 0, // placeholder, we want to know how many total zones were visited by this user at this hour of the show
			"zonePath": [ [ previousZoneTitle, currentZoneTitle ]  ], // we want to know the path a user took example : " at 9am user 123 went from zone1 to zone 3, then from zone 3 to zone 2, then from zone2 back to zone 1"
			"zonesVisited" : { // remember this is per current hour of the show
								zoneTitle  : { title : zoneTitle, minutesInZone : 0 },
							 },
		  },
  
	  }
  }
	*/

	/**
	 * this generates an object mapped to userId
	 * @param {number[]} hourRange
	 * @param {obj[]} checkDates
	 * @param {obj} zoneData
	 * @param {string} zoneTitle
	 * @param {obj} userInfo
	 * @returns {obj}
	 */
	calculateZonalUserInfoPerHour(hourRange, checkDates, zoneData, zoneTitle, userInfo = {}) {
		for (let dateIndex = 0; dateIndex < checkDates.length; dateIndex++) {
			let checkDate = checkDates[dateIndex];
			for (let i = hourRange[0]; i <= hourRange[1]; i++) {
				let timeKey = checkDate.valueOf();
				timeKey += i * 3600000;
				if (zoneData != null && timeKey.toString() in zoneData) {
					userInfo = this.processHourlyZoneSessions(zoneData[timeKey]['sessions'], userInfo, zoneTitle, i);
				}
			}
		}

		return userInfo;
	}

	// this will return an object mapping userIds to the zones visited and the duration spent

	/**
	 * this generates an object mapped to userId for each hour of the show
	 * @param {obj} sessionsData
	 * @param {obj} userInfo
	 * @param {string} zoneTitle
	 * @param {number} currentHour
	 * @returns {obj} userInfo
	 */
	processHourlyZoneSessions(sessionsData, userInfo, zoneTitle, currentHour) {
		// Initialize userInfo structure if necessary
		if (!userInfo[currentHour]) {
			userInfo[currentHour] = this.initializeUserInfoStructure();
		}

		// Iterate through each sessionData
		for (const sessionId in sessionsData) {
			const session = sessionsData[sessionId];
			const entityId = session.entityId;
			const sessionDuration = session.duration; // Duration in minutes

			// Initialize user info if entityId is not already initialized for currentHour
			this.initializeUserIfNeeded(userInfo, currentHour, entityId);

			// Update zonesVisited for the current zoneTitle and currentHour
			this.updateZonesVisited(userInfo, currentHour, entityId, zoneTitle, sessionDuration);

			// Update zonePath
			this.updateZonePath(userInfo, currentHour, entityId, zoneTitle);

			// Update max and min users at zone
			this.updateMaxMinUsersAtZone(userInfo, currentHour, entityId);

			// Update totalZonesVisitedPerHour and totalUniqueUsersPerHour
			this.updateTotalCounts(userInfo, currentHour);

			// Calculate averageZonesPerUser
			this.calculateAverageZonesPerUser(userInfo, currentHour);
		}

		return userInfo;
	}

	// Helper functions

	initializeUserInfoStructure() {
		return {
			uniqueUsersPerShowHour: {},
			totalZonesVisitedPerHour: 0,
			totalUniqueUsersPerHour: 0,
			averageZonesPerUser: 0,
			maxUsersAtZone: 0,
			minUsersAtZone: Infinity,
		};
	}

	initializeUserIfNeeded(userInfo, currentHour, entityId) {
		if (!userInfo[currentHour].uniqueUsersPerShowHour[entityId]) {
			userInfo[currentHour].uniqueUsersPerShowHour[entityId] = {
				zonesVisited: {},
				totalZonesVisited: 0,
				zonePath: [],
			};
		}
	}

	updateZonesVisited(userInfo, currentHour, entityId, zoneTitle, sessionDuration) {
		const user = userInfo[currentHour].uniqueUsersPerShowHour[entityId];

		if (!user.zonesVisited[zoneTitle]) {
			user.zonesVisited[zoneTitle] = {
				title: zoneTitle,
				minutesInZone: sessionDuration,
			};
		} else {
			user.zonesVisited[zoneTitle].minutesInZone += sessionDuration;
		}

		// Update totalZonesVisited for the user at this hour
		user.totalZonesVisited += 1;
	}

	updateZonePath(userInfo, currentHour, entityId, zoneTitle) {
		const userStats = userInfo[currentHour].uniqueUsersPerShowHour[entityId];
		if (userStats.zonePath.length === 0) {
			userStats.zonePath.push(['entrance', zoneTitle]); // Start with "entrance"
		} else {
			const lastPath = userStats.zonePath[userStats.zonePath.length - 1];
			if (lastPath[1] !== zoneTitle) {
				userStats.zonePath.push([lastPath[1], zoneTitle]);
			}
		}
	}

	/**
	 * Updates max and min number of zones visited by any unique user for each hour.
	 * @param {Object} userInfo - Object containing user information.
	 * @param {string} currentHour - Current hour identifier.
	 * @param {string} entityId - Entity identifier (user or similar).
	 */
	updateMaxMinUsersAtZone(userInfo, currentHour, entityId) {
		const zonesVisitedCount = Object.keys(userInfo[currentHour].uniqueUsersPerShowHour[entityId].zonesVisited).length;

		// Update maxUsersAtZone if current count is greater
		if (zonesVisitedCount > userInfo[currentHour].maxUsersAtZone) {
			userInfo[currentHour].maxUsersAtZone = zonesVisitedCount;
		}

		// Update minUsersAtZone if current count is lesser
		if (zonesVisitedCount < userInfo[currentHour].minUsersAtZone) {
			userInfo[currentHour].minUsersAtZone = zonesVisitedCount;
		}
	}

	updateTotalCounts(userInfo, currentHour) {
		userInfo[currentHour].totalZonesVisitedPerHour++;
		userInfo[currentHour].totalUniqueUsersPerHour = Object.keys(userInfo[currentHour].uniqueUsersPerShowHour).length;
	}

	calculateAverageZonesPerUser(userInfo, currentHour) {
		userInfo[currentHour].averageZonesPerUser = userInfo[currentHour].totalZonesVisitedPerHour / userInfo[currentHour].totalUniqueUsersPerHour;
	}

	/**
	 * Retrieves the hourly summary of user zone travel.
	 * @param {Array} zones - Array of zones to analyze.
	 * @param {Object} sessionData - Session data containing zone sessions.
	 * @param {Array} hourRange - Range of hours to analyze.
	 * @param {Array} timeRange - Range of timestamps to analyze.
	 * @returns {Object} Object containing the hourly summary of user zone travel.
	 */
	addExitToZonePath(userInfo) {
		// Loop through each hour in userInfo
		for (const currentHour in userInfo) {
			const uniqueUsersPerHour = userInfo[currentHour].uniqueUsersPerShowHour;
			// Loop through each user in current hour
			for (const entityId in uniqueUsersPerHour) {
				const userStats = uniqueUsersPerHour[entityId];
				// Check if user's path ends with "exit"
				if (userStats.zonePath.length > 0) {
					const lastPath = userStats.zonePath[userStats.zonePath.length - 1];
					if (lastPath[1] === 'exit') {
						// Call function to check if user re-entered
						this.updateUserInfoIngressEgress(userInfo, currentHour, entityId, userStats);
					}
				}
			}
		}
		return userInfo;
	}

	/**
	 * Updates user information for ingress (entering) and egress (exiting) from the show.
	 * @param {Object} userInfo - Object containing user information.
	 * @param {string} currentHour - Current hour of the show being analyzed.
	 * @param {string} entityId - Entity ID of the user.
	 * @param {Object} userStats - User statistics object containing zone path and other details.
	 */
	updateUserInfoIngressEgress(userInfo, currentHour, entityId, userStats) {
		// Initialize counters if not already present
		if (!userInfo[currentHour].numUsersEnterShow) {
			userInfo[currentHour].numUsersEnterShow = 0;
		}
		if (!userInfo[currentHour].numUsersExitShow) {
			userInfo[currentHour].numUsersExitShow = 0;
		}

		// Iterate over subsequent hours to find re-entry
		for (let nextHour = parseInt(currentHour) + 1; nextHour <= Object.keys(userInfo).length; nextHour++) {
			// Check if the user id appears again in nextHour, signifying re-entry
			if (userInfo[nextHour] && userInfo[nextHour].uniqueUsersPerShowHour[entityId]) {
				const nextHourUserStats = userInfo[nextHour].uniqueUsersPerShowHour[entityId];
				// Check if next hour's path starts with re-entry condition
				if (nextHourUserStats.zonePath.length > 0) {
					const nextHourFirstPath = nextHourUserStats.zonePath[0];
					if (nextHourFirstPath[0] === userStats.zonePath[userStats.zonePath.length - 1][0]) {
						// Update current hour's path to end with "exit"
						userStats.zonePath[userStats.zonePath.length - 1] = [userStats.zonePath[userStats.zonePath.length - 1][0], 'exit'];
						// Update next hour's path to start with "entrance"
						nextHourUserStats.zonePath.unshift(['entrance', nextHourFirstPath[1]]);
						// Increment numUsersEnterShow and numUsersExitShow
						userInfo[currentHour].numUsersExitShow++;
						userInfo[nextHour].numUsersEnterShow++;
					}
				}
			}
		}
	}



	/**
	 * Steps through the hours in the range and calculates the unique users who visited the zone
	 * @param {number[]} hourRange
	 * @param {obj[]} checkDates
	 * @param {obj} zoneData
	 * @returns {number[]}
	 */
	calculateZonesPerUser(hourRange, checkDates, zoneData) {
		let _usersInZone = [];

		for (let dateIndex = 0; dateIndex < checkDates.length; dateIndex++) {
			let checkDate = checkDates[dateIndex];

			for (let i = hourRange[0]; i <= hourRange[1]; i++) {
				let timeKey = checkDate.valueOf();
				timeKey += i * 3600000;
				if (zoneData != null && timeKey.toString() in zoneData) {
					if (zoneData[timeKey]) {
						_usersInZone.push(timeKey);
					}
				}
			}
		}

		return _usersInZone;
	}

	updateLastTrackDict(latestMap, lastTrackDict) {
		for (const [key, track] of Object.entries(latestMap)) {
			if (key in lastTrackDict) {
				if (lastTrackDict[key][lastTrackDict[key].length - 1] != track['Zone']) {
					lastTrackDict[key].push(track['Zone']);
				}
			} else {
				lastTrackDict[key] = [track['Zone']];
			}
		}

		return lastTrackDict;
	}

	calculateAverageTime(zoneTotals, totalTime) {
		let averageTime = 0;
		if (zoneTotals['Entry']) {
			averageTime = (totalTime * 60) / zoneTotals['Entry'].size;
		} else {
			averageTime = 22;
		}

		return averageTime;
	}
}

export const dataService = new DataService();
