import React, { useEffect, useRef, useState } from 'react';
import './canvas-map.css'; // Import CSS file
import { queryService } from '../../../services/query.service';
import { configService } from '../../../services/config.service';
import { isPointInPolygon } from '../../../services/utility.service';

const colors = [
	'#5F6064', '#27BBF4', '#26E1C0', '#F96A4A', '#F9A94A'
];

const CanvasMap = ({ distinctUsers, totalAttention, engagedUsers, avgAttention, dataIndex = 0 }) => {
	const canvasRef = useRef(null);
	const [selectedZone, setSelectedZone] = useState(null);
	const [hoveredZone, setHoveredZone] = useState(null);

	const [curData, setCurData] = useState(null); // State to hold data from subscription
	const [config, setConfig] = useState(null); // State to hold data from subscription

	useEffect(() => {
		const dataSets = [{}, distinctUsers, totalAttention, engagedUsers, avgAttention];
		const dataSet = dataSets[dataIndex];

		let normalizedData = {};
		if (dataSet && Object.keys(dataSet).length !== 0) {
			// Get the max and min number for data
			const dataValues = Object.values(dataSet).map((item) => item);
			const maxData = Math.max(...dataValues);
			const minData = Math.min(...dataValues);

			// Normalize the data to a value from 0-255 and update the dictionary
			Object.entries(dataSet).forEach(([key, item]) => {
				const normalizedValue = Math.round(((item - minData) / (maxData - minData)) * 255);
				const hexValue = normalizedValue.toString(16).padStart(2, '0');
				normalizedData[key] = `${hexValue}`;
			});
		}

		const canvas = canvasRef.current;
		const ctx = canvas.getContext('2d');

		if (curData && curData.showName) {
			const img = new Image();
			img.src = config.dataURL + '/api/image/' + curData.showName + '.jpg';
			let jsonData = curData.mapInfo;
			img.onload = () => {
				// Set canvas dimensions based on image size
				canvas.width = img.width;
				canvas.height = img.height;

				// Draw the image on canvas
				ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

				// Function to draw zones on canvas
				const drawZones = () => {
					let index = 1;
					jsonData.forEach((item) => {
						const { scaledPoints, name } = item;

						let rgbaColor = colors[dataIndex] + (normalizedData[name] || '80');
						// Draw lines to connect points	
						ctx.strokeStyle = colors[dataIndex];
						ctx.fillStyle = rgbaColor;

						let points = scaledPoints.map((point) => {
							return {
								x: point.x * canvas.width,
								y: point.y * canvas.height
							}
						});

						if (points.length > 0) {
							ctx.beginPath();
							ctx.moveTo(points[0].x, points[0].y);

							for (let i = 1; i < points.length; i++) {
								ctx.lineTo(points[i].x, points[i].y);
							}
							ctx.closePath();
							ctx.fill();
							ctx.stroke();
						}

						const zoneCenterX = (scaledPoints.reduce((acc, cur) => acc + cur.x, 0) / scaledPoints.length) * canvas.width;
						const zoneCenterY = (scaledPoints.reduce((acc, cur) => acc + cur.y, 0) / scaledPoints.length) * canvas.height;

						const fontSize = canvas.width * 0.025;
						ctx.font = `bold ${fontSize}px Arial, sans-serif`;
						ctx.fillStyle = colors[0]; // Ensure the font color is red
						ctx.textAlign = 'center';
						if (!hoveredZone || hoveredZone.name != name) {
							ctx.strokeStyle = 'black'; // Border color
							ctx.lineWidth = 1; // Border width
							// First, draw the text outline
							ctx.strokeText(index, zoneCenterX, zoneCenterY);
							ctx.fillText(index, zoneCenterX, zoneCenterY); // Adjusted position to 5% left
						}

						index++;
					});
				};

				// Initial draw of zones
				drawZones();

				// Handle canvas hover and mouseout events
				const handleCanvasHover = (e) => {
					const rect = canvas.getBoundingClientRect();
					const mouseX = e.clientX - rect.left;
					const mouseY = e.clientY - rect.top;

					let foundHover = false;
					jsonData.forEach((item) => {
						const { scaledPoints } = item;
						// Calculate the centroid of the polygon
						const centroid = scaledPoints.reduce((acc, point) => {
							return {
								x: acc.x + (point.x * canvas.offsetWidth),
								y: acc.y + (point.y * canvas.offsetHeight)
							};
						}, { x: 0, y: 0 });

						centroid.x /= scaledPoints.length;
						centroid.y /= scaledPoints.length;

						// Calculate the distance from the centroid to the mouse point
						const distance = Math.sqrt(Math.pow(centroid.x - mouseX, 2) + Math.pow(centroid.y - mouseY, 2));

						// Assuming you have a defined threshold distance
						const thresholdDistance = canvas.offsetWidth * 0.025; // Change this value to your desired threshold

						if (distance <= thresholdDistance) {
							setHoveredZone(item);
							foundHover = true;
						}
					});

					if (!foundHover) {
						setHoveredZone(null); // Clear hovered zone
					} else {
						// Redraw zones with highlighted effect
						ctx.clearRect(0, 0, canvas.width, canvas.height);
						ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
						drawZones();

						// Display zone name next to hovered zone
						if (hoveredZone) {
							const { scaledPoints } = hoveredZone;
							const zoneCenterX = (scaledPoints.reduce((acc, cur) => acc + cur.x, 0) / scaledPoints.length) * canvas.width;
							const zoneCenterY = (scaledPoints.reduce((acc, cur) => acc + cur.y, 0) / scaledPoints.length) * canvas.height;

							const fontSize = canvas.width * 0.03;
							ctx.font = `bold ${fontSize}px Arial, sans-serif`;
							ctx.fillStyle = 'black'; // Ensure the font color is red
							ctx.strokeStyle = 'black'; // Border color
							ctx.lineWidth = 1; // Border width

							let text = hoveredZone.name + " - " + dataSet[hoveredZone.name];
							// First, draw the text outline
							ctx.strokeText(hoveredZone.name, zoneCenterX, zoneCenterY);
							ctx.fillText(hoveredZone.name, zoneCenterX, zoneCenterY); // Adjusted position to 5% left
						}
					}
				};

				canvas.addEventListener('mousemove', handleCanvasHover);

				canvas.addEventListener('mouseout', () => {
					// Clear canvas on mouse out
					ctx.clearRect(0, 0, canvas.width, canvas.height);
					ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
					drawZones(); // Redraw zones
					setHoveredZone(null); // Clear hovered zone
				});


				return () => {
					// Clean up event listener
					canvas.removeEventListener('mousemove', handleCanvasHover);
				};
			};
		}

		const querySub = queryService.curData$.subscribe((data) => {
			setCurData(data); // Update local state with new data
			// You can get the map image from your config's dataURL + "/api/image/" data.showName + ".jpg"
			// You can get your map information (replace jsonData) from data.mapInfo
		});

		const configSub = configService.config$.subscribe((config) => {
			setConfig(config); // Update local state with new config
			// Here is where you can get the dataURL from the config
			// It will be config.dataURL
		});

		// Return cleanup function
		return () => {
			// Unsubscribe from both subscriptions
			querySub.unsubscribe();
			configSub.unsubscribe();
		};
	}, [selectedZone, hoveredZone, queryService, configService, config, curData, dataIndex, distinctUsers, totalAttention, engagedUsers, avgAttention]);

	// Handle legend item click
	const handleLegendItemClick = (zone) => {
		setSelectedZone(zone === selectedZone ? null : zone);
	};

	return (
		<div className="canvas-main">
			<canvas ref={canvasRef} className="canvas-map" />
		</div>
	);
};

export default CanvasMap;
