import React, { useRef, useEffect, useState } from "react";
import "./show-view.css";
import ChartComponent from "../../highcharts/base-card-functional";
import { CHART_TYPES } from "../../../enums/types.enum";
import TableView from "../table-view/table-view";
// import MapChart from '../highcharts/map-chart/map-chart';
import CanvasMap from "../canvas-map/canvas-map";

import AvgAttentionChart from "../../highcharts/column-charts/show-view/avg-attention";
import DistinctUsersChart from "../../highcharts/column-charts/show-view/distinct-users";
import EngagedUsersChart from "../../highcharts/column-charts/show-view/engaged-users";
import TotalAttentionChart from "../../highcharts/column-charts/show-view/total-attention";
import BarChart from "../../highcharts/bar-chart/bar-chart";
import AreaChart from "../../highcharts/area-chart/area-chart";

const ShowView = ({
	props: {
		apps,
		zones,
		timezone,
		pairingData,
		estimatedStaff,
		timeRange,
		hourRange,
		data,
		map,
		chartProps,
		dataIndex,
	},
}) => {
	const {
		tableViewMetrics,
		variableDashboardMetrics,
		avgZonesPerUserByHour,
		avgAppsPerUserByHour,
		avgAttentionMinutesPerZone,
		distinctUsersPerZone,
		engagedUsersPerZone,
		totalAttentionMinutesPerZone,
		highLowAvgZonesPerUser,
	} = chartProps;

	const showVariableProps = {
		apps,
		zones,
		timezone,
		timeRange,
		hourRange,
		data,
	};

	const variableDashboardProps = {
		data: variableDashboardMetrics.data,
		chartType: CHART_TYPES.AREA,
		yAxisName: variableDashboardMetrics.title,
		xAxisName: "Hour",
	};

	const tableViewProps = {
		data: tableViewMetrics.zoneData,
		title: tableViewMetrics.title,
		totalData: tableViewMetrics.totalData,
		view: "Zones",
	};

	const avgAttentionMinutesPerZoneProps = {
		data: avgAttentionMinutesPerZone,
		chartType: CHART_TYPES.COLUMN,
		yAxisName: "Avg Attention Minutes",
		xAxisName: "Zone",
		dataType: "averageAttention",
	};

	const distinctUsersByZoneProps = {
		data: distinctUsersPerZone,
		chartType: CHART_TYPES.COLUMN,
		yAxisName: "Distinct Users",
		xAxisName: "Zone",
		dataType: "distinctUsers",
	};

	const engagedUsersPerZoneProps = {
		data: engagedUsersPerZone,
		chartType: CHART_TYPES.COLUMN,
		yAxisName: "Engagements",
		xAxisName: "Zone",
		dataType: "engagements",
	};

	const totalAttentionMinutesPerZoneProps = {
		data: totalAttentionMinutesPerZone,
		chartType: CHART_TYPES.COLUMN,
		yAxisName: "Total Attention Minutes",
		xAxisName: "Zone",
		dataType: "attentionMinutes",
	};

	const canvasMapProps = {
		distinctUsers: distinctUsersPerZone,
		totalAttention: totalAttentionMinutesPerZone,
		engagedUsers: engagedUsersPerZone,
		avgAttention: avgAttentionMinutesPerZone,
		dataIndex: dataIndex,
	};

	const parentRef = useRef(null);
	const [parentHeight, setParentHeight] = useState(0);

	useEffect(() => {
		if (parentRef.current) {
			setParentHeight(parentRef.current.clientHeight);
		}
	}, [parentRef]);

	return (
		<div key="show-main" className="show-main">
			<div key="show-map-all" className="show-map-all" ref={parentRef}>
				<div key="show-map" className="show-map">
					<CanvasMap
						distinctUsers={distinctUsersPerZone}
						totalAttention={totalAttentionMinutesPerZone}
						engagedUsers={engagedUsersPerZone}
						avgAttention={avgAttentionMinutesPerZone}
						dataIndex={dataIndex}
					></CanvasMap>
				</div>

				<div key="show-top-bottom" className="show-top-bottom">
					<div key="show-top" className="show-top">
						<TableView props={tableViewProps} value="top"></TableView>
					</div>
					<div key="show-bottom" className="show-bottom">
						<TableView props={tableViewProps}></TableView>
					</div>
				</div>
			</div>

			<div className="show-variable-dashboard" key="show-variable-dashboard">
				<AreaChart
					props={variableDashboardProps}
					chartType={CHART_TYPES.AREA}
				></AreaChart>
			</div>

			<div key="attention-distinct" className="show-attention-distinct">
				<BarChart
					props={distinctUsersByZoneProps}
				></BarChart>
				<BarChart
					props={engagedUsersPerZoneProps}>

				</BarChart>
			</div>

			<div key="engaged-time-users" className="show-engaged-time-users">
				<BarChart
					props={totalAttentionMinutesPerZoneProps}
				></BarChart>
				<BarChart
					props={avgAttentionMinutesPerZoneProps}
				></BarChart>
			</div>
		</div>
	);
};

export default ShowView;
