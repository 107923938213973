import { BehaviorSubject, first } from 'rxjs';
import { configService } from './config.service';

let dataURL = 'https://stag-data.trumetrix.net';

class FetchService {
	get data$() {
		return this._data$.asObservable();
	}

	get queries$() {
		return this._queries$.asObservable();
	}
	constructor() {
		this._data$ = new BehaviorSubject(null);
		this._queries$ = new BehaviorSubject(null);

		configService.config$
			.pipe(first((config) => config !== null))
			.subscribe((config) => {
				dataURL = config.dataURL;
				this.fetchQueries();
			});
	}

	fetchData(timeRange, interactives, zones) {
		let obj = JSON.stringify({
			key: '56kebRtuvWGnUCodiOAdt365Y014',
			time: timeRange,
			interactives: interactives,
			zones: zones,
		});

		console.log('Fetching Zones: ' + zones);
		console.log('Fetching Interactives: ' + interactives);

		fetch(dataURL + '/api/dbPull', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: obj,
		})
			.then((r) => r.json())
			.then((response) => {
				this._data$.next(response);
			})
			.catch((e) => {
				console.log(e);
			});
	}

	fetchPreQuery(query) {
		let obj = JSON.stringify({
			key: '56kebRtuvWGnUCodiOAdt365Y014',
			query: query,
		});

		fetch(dataURL + '/api/presetQuery', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: obj,
		})
			.then((r) => r.json())
			.then((response) => {
				this._data$.next(response);
			})
			.catch((e) => {
				console.log(e);
			});
	}

	fetchCSV(name, data) {
		let obj = JSON.stringify({
			key: '56kebRtuvWGnUCodiOAdt365Y014',
			rows: data,
		});

		fetch(dataURL + '/api/csv', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: obj,
		})
			.then((res) => res.blob())
			.then((blob) => {
				var file = new Blob([blob], { type: 'text/csv' });
				var fileURL = URL.createObjectURL(file);

				var fileLink = document.createElement('a');
				fileLink.href = fileURL;
				fileLink.download = name + '.csv';
				fileLink.click();
			});
	}

	fetchQueries() {
		fetch(dataURL + '/api/queries')
			.then((r) => r.json())
			.then((response) => {
				this._queries$.next(response);
			})
			.catch((e) => {
				console.log(e);
			});
	}
}

export const fetchService = new FetchService();
