import React from "react";
import "./dashboard-metrics-button.css";

const DashboardMetricsButton = (props) => {
	// Format totalValue with commas and no decimal places
	const formattedTotal = props.totalValue.toLocaleString(undefined, {
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	});

	return (
		<button
			key={`container-dashboard-button-${props.idx}`}
			value={props.idx}
			title={props.title}
			id={`container-dashboard-button-${props.idx + 1}`}
			onClick={() => props.changeVariableDashboardMetrics(props.idx)}
			className="dashboard-metrics-button"
		>
			<div className="metrics-button-total">
				{formattedTotal}{" "}
				<div className="button-icon">
					<img src={props.icon} id="button-icon" />
				</div>
			</div>
			<div className="metrics-button-title">{props.title}</div>
			<div className="metrics-button-subtitle">{props.subtitle}</div>
		</button>
	);
};

export default DashboardMetricsButton;
