// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/fonts/Inter-VariableFont_slnt,wght.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Will need to go back and redefine Inter where it is applied throughout*/\n@font-face {\n\tfont-family: 'Inter';\n\tfont-style: italic;\n\tfont-weight: normal;\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")\n}\n\n@font-face {\n\tfont-family: 'Inter';\n\tfont-style: normal;\n\tfont-weight: normal;\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")\n}\n\n@font-face {\n\tfont-family: 'Inter';\n\tfont-style: normal;\n\tfont-weight: bold;\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")\n}", "",{"version":3,"sources":["webpack://./public/styles/fonts.css"],"names":[],"mappings":"AAAA,0EAA0E;AAC1E;CACC,oBAAoB;CACpB,kBAAkB;CAClB,mBAAmB;CACnB;AACD;;AAEA;CACC,oBAAoB;CACpB,kBAAkB;CAClB,mBAAmB;CACnB;AACD;;AAEA;CACC,oBAAoB;CACpB,kBAAkB;CAClB,iBAAiB;CACjB;AACD","sourcesContent":["/* Will need to go back and redefine Inter where it is applied throughout*/\n@font-face {\n\tfont-family: 'Inter';\n\tfont-style: italic;\n\tfont-weight: normal;\n\tsrc: url('../assets/fonts/Inter-VariableFont_slnt,wght.ttf')\n}\n\n@font-face {\n\tfont-family: 'Inter';\n\tfont-style: normal;\n\tfont-weight: normal;\n\tsrc: url('../assets/fonts/Inter-VariableFont_slnt,wght.ttf')\n}\n\n@font-face {\n\tfont-family: 'Inter';\n\tfont-style: normal;\n\tfont-weight: bold;\n\tsrc: url('../assets/fonts/Inter-VariableFont_slnt,wght.ttf')\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
