


export var stringToColour = function (str) {
	var hash = 0;
	for (var i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	var colour = '#';
	for (var i = 0; i < 3; i++) {
		var value = (hash >> (i * 8)) & 0xFF;
		colour += ('00' + value.toString(16)).substr(-2);
	}
	return colour;
}

export var getRandomColor = function () {
	var letters = '0123456789ABCDEF';
	var color = '#';
	for (var i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}
	return color;
}

export var stringToSeed = function (str) {
	var hash = 5381;
	for (var i = 0; i < str.length; i++) {
		hash = (hash * 33) ^ str.charCodeAt(i);
	}
	return hash >>> 0;
}


export var getRandomSeededColor = function (seed) {
	var randomChannel = function (seed, channel) {
		var x = (seed ^ channel) >>> 0;
		x = (x << 13) ^ x;
		return ((x >>> 0) / (Math.pow(2, 32) - 1)).toFixed(6);
	};

	var r = Math.floor(randomChannel(seed, 0) * 256);
	var g = Math.floor(randomChannel(seed, 1) * 256);
	var b = Math.floor(randomChannel(seed, 2) * 256);

	console.log(r, b, g)
	return 'rgb(' + r + ',' + g + ',' + b + ')';
}

export var getRandomHashColor = function (string) {
	var md5 = require('md5');
	return '#' + md5(string).slice(0, 6);
}

/** 
 * Takes a string phrase and breaks it into separate phrases 
 * no bigger than 'maxwidth', breaks are made at complete words.
 */
export const formatLabel = function (str, maxwidth) {
	var sections = [];
	var words = str.split(" ");
	var temp = "";

	for (let index = 0; index < words.length; index++) {
		let item = words[index];
		if (temp.length > 0) {
			var concat = temp + ' ' + item;

			if (concat.length > maxwidth) {
				sections.push(temp);
				temp = "";
			}
			else {
				if (index == (words.length - 1)) {
					sections.push(concat);
					continue;
				}
				else {
					temp = concat;
					continue;
				}
			}
		}

		if (index == (words.length - 1)) {
			sections.push(item);
			continue;
		}

		if (item.length < maxwidth) {
			temp = item;
		}
		else {
			sections.push(item);
		}
	}

	if (sections.length >= 3) {
		return sections.slice(0, 3);
	} else {
		return sections;
	}
}

export function convertDateToUTC(utcDate, end = false) {
	if (end == false) {
		return new Date(Date.UTC(utcDate.getUTCFullYear(),
			utcDate.getUTCMonth(),
			utcDate.getUTCDate(),
			0,
			0,
			0));
	}
	else {
		return new Date(Date.UTC(utcDate.getUTCFullYear(),
			utcDate.getUTCMonth(),
			utcDate.getUTCDate(),
			24,
			0,
			0));
	}
}