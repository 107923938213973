import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "./stacked-area-chart.css";

const StackedAreaChart = ({ props }) => {
	const [seriesData, setSeriesData] = useState([]);
	const [xCategories, setXCategories] = useState([]);
	const [isStacked, setIsStacked] = useState(false); // State for toggling stack

	useEffect(() => {
		if (props.view === "Apps") {
			let newSeriesData = [];
			let newCategories = [];

			const colors = Highcharts.getOptions().colors;

			for (const zone in props.data) {
				let hoursOfShow = props.data[zone];
				let tempData = [];

				let hours = Object.keys(hoursOfShow);

				for (const currentHour in hoursOfShow) {
					let currentValue = hoursOfShow[currentHour];
					tempData.push(currentValue);
				}

				const h = hours.map((hour) => {
					let temp = parseInt(hour);
					let formattedHour = `${temp} am`;

					if (temp === 12) {
						formattedHour = `${temp} pm`;
					} else if (temp > 12) {
						formattedHour = `${temp - 12} pm`;
					}
					return formattedHour;
				});

				newCategories = h;

				const randomColor = colors[Math.floor(Math.random() * colors.length)];

				newSeriesData.push({ name: zone, data: tempData, color: randomColor });
			}

			setSeriesData(newSeriesData);
			setXCategories(newCategories);
		}
	}, [props.data, props.view]);

	const chartOptions = {
		chart: {
			type: "area",
			height: null,
			width: null,
			marginTop: 75, // Increase top margin
			marginRight: 50, // Increase right margin
			marginBottom: 100, // Increase bottom margin
			marginLeft: 75, // Increase left margin
			spacingTop: 10,
			spacingRight: 10,
			spacingBottom: 10,
			spacingLeft: 10,
			backgroundColor: "var(--white)",
		},
		title: {
			text: "Zone Data by Hour",
			align: "left",
			x: 10, // Position the title at the left edge
			marginTop: 10,

			style: {
				fontSize: "1em",
				fontFamily: "Inter",
			},
		},
		xAxis: {
			categories: xCategories,
		},
		yAxis: {
			title: {
				text: props.yAxisName || "Y Axis",
			},
			type: "linear",
		},
		credits: {
			enabled: false,
		},
		series: seriesData,
		tooltip: {
			formatter: function () {
				// Calculate the total for the current x point
				const total = this.series.chart.series.reduce((sum, series) => {
					return sum + series.data[this.point.index].y;
				}, 0);

				const currentX = this.x; // Get the current X-axis value

				return `<span style="font-size: 16px">Zone: ${this.series.name}<br/>${props.yAxisName}: ${this.y}<br/>Total ${props.yAxisName} at ${currentX}: ${total}</span>`;
			},
			backgroundColor: "rgba(255, 255, 255, 0.85)",
			borderWidth: 1,
			borderColor: "#ccc",
			shadow: true,
			style: {
				fontSize: "14px",
			},
			shared: false,
		},
		plotOptions: {
			area: {
				stacking: isStacked ? "normal" : undefined, // Toggle stacking
				fillOpacity: isStacked ? undefined : 0.5,
				lineWidth: 1,
				marker: {
					enabled: true,
				},
			},
		},
		legend: {
			enabled: true,
			itemStyle: {
				fontSize: ".6em",
			},
			itemMarginTop: 2, // Adjust the top margin between legend items
			itemMarginBottom: 2,
			margin: 5,
		},
	};

	const toggleStacking = () => {
		setIsStacked((prev) => !prev); // Toggle stacking state
	};

	return (
		<div className="stacked-chart-container" style={{ position: "relative" }}>
			<button
				className="toggle-button"
				style={{
					position: "absolute",
					top: "10px",
					right: "10px",
					padding: "5px 10px",
					backgroundColor: "#007bff",
					color: "white",
					border: "none",
					borderRadius: "4px",
					cursor: "pointer",
					zIndex: 10,
				}}
				onClick={toggleStacking}
			>
				{isStacked ? "Switch to Normal" : "Switch to Stacked"}
			</button>
			<HighchartsReact highcharts={Highcharts} options={chartOptions} />
		</div>
	);
};

export default StackedAreaChart;
