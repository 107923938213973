import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsSankey from 'highcharts/modules/sankey';
import './sankey-diagram.css';

if (typeof Highcharts === 'object') {
	HighchartsSankey(Highcharts);
}

const generateRandomData = (count) => {
	const data = [];

	for (let i = 0; i < count; i++) {
		data.push({
			from: `Source ${Math.floor(Math.random() * 17)}`,
			to: `Target ${Math.floor(Math.random() * 17)}`,
			weight: Math.floor(Math.random() * 100),
		});
	}

	return data;
};

const SankeyDiagram = () => {
	const options = {
		chart: {
			type: 'sankey',
		},
		toolTip: {
			style: {
				fontSize: '16px',
			},
		},
		title: {
			text: 'Sankey Diagram with Custom Columns',
		},
		series: [
			{
				name: '',
				keys: ['from', 'to', 'weight'],
				data: generateRandomData(20),
			},
			{
				name: '',
				keys: ['from', 'to', 'weight'],
				data: generateRandomData(20),
			},
			{
				name: '',
				keys: ['from', 'to', 'weight'],
				data: generateRandomData(20),
			},
			{
				name: '',
				keys: ['from', 'to', 'weight'],
				data: generateRandomData(20),
			},
		],
	};

	return (
		<div className="sankey-main">
			<div className="first-event-container">
				<div className="sankey-first-event-text">First Event</div>
			</div>
			<div className="sankey-highcharts-container">
				<HighchartsReact highcharts={Highcharts} options={options} />
			</div>
			<div className="sankey-last-event-container">
				<div className="sankey-last-event-text">Last Event</div>
			</div>
		</div>
	);
};

export default SankeyDiagram;
