import React from 'react';
import './login.css';

import { useAuth0 } from "@auth0/auth0-react";

const Login = () => {
	const {
		loginWithRedirect,
	} = useAuth0();

	return (
		<div id='login-container'>
			<div id='login-logo'>
			</div>
			<div
				id="login-btn"
				onClick={() => loginWithRedirect({})}
			>Login</div>
			<div id='login-footer'>
				<div id='powered-by'>Powered by</div>
				<div id='ixp-logo'></div>
			</div>
		</div>
	);
}

export default Login

