import { BehaviorSubject } from "rxjs"

class ConfigService {
	constructor() {
		this.config$ = new BehaviorSubject(null);
		const userAgent = navigator.userAgent.toLowerCase();
		// are we using electron?
		if (userAgent.indexOf(' electron/') > -1) {
			const fs = window.require('fs');
			const configFile = JSON.parse(fs.readFileSync('./config.json', 'utf8'));
			this.config$.next(configFile);
		} else {
			fetch("config.json")
				.then(response => response.json())
				.then(json => {
					fetch('/data')
						.then(r => r.json())
						.then(data => {
							json.dataURL = data.dataURL;
							this.config$.next(json);
						})
						.catch(e => {
							console.log(e);
							this.config$.next(json);
						});
				});
		}
	}
}

export const configService = new ConfigService();