export const PAGE_ENUM = {
	CAMERA_SETUP: 'camera_setup',
	ZONE_SETUP: 'zone_setup',
	DEFAULT: 'default',
	DASHBOARD: 'dashboard',
	APP_SETUP: 'app_setup',
};

export const SOCKET_COMMANDS = {
	CAMERA_SCAN: 'scan',
	SET_CAM_INFO: 'camInfo',
	SET_ZONE_INFO: 'zoneInfo',
	START_YOLO: 'startYolo',
	CAMERA_STATUS: 'cameraStatus',
	CAMERAS: 'cameras',
	SET_APP_INFO: 'appInfo',
	ZONES: 'zones',
};

export const CHART_TYPES = {
	LINE: 'line',
	BAR: 'bar',
	COLUMN: 'column',
	AREA: 'area',
};
