import React from 'react';
import './table-view.css'; // Import your CSS file for styling

const TableView = ({ props, value }) => {
	const metricColors = {
		users: 'var(--teal)',
		engagements: 'var(--green)',
		attentionMinutes: 'var(--orange)',
		timePerUser: 'var(--yellow)',
	};

	let data = [];
	let topThreeValues = [-Infinity, -Infinity, -Infinity];
	let bottomThreeValues = [Infinity, Infinity, Infinity];
	let topThreeZones = [];
	let bottomThreeZones = [];
	let titleForTable = '';

	if (props && props.data) {
		const view = props.view || 'Zones';
		titleForTable = props.title ? (value === 'top' ? `Top Three ${view} Sorted by ${props.title}` : `Bottom Three ${view} Sorted By ${props.title}`) : '';

		// Convert props.data to an array of objects [{ zoneTitle, zoneValue }]
		const zoneDataArray = Object.entries(props.data).map(([zoneTitle, zoneValue]) => ({ zoneTitle, zoneValue }));

		// TODO: fix data to remove infinity values
		zoneDataArray.map((zone) => {
			if (zone.zoneValue === Infinity || zone.zoneValue === -Infinity) {
				zone.zoneValue = 0;
			}
		});

		// Sort the array by zoneValue in descending order for top or ascending for bottom
		zoneDataArray.sort((a, b) => (value === 'top' ? b.zoneValue - a.zoneValue : a.zoneValue - b.zoneValue));

		// Get top three zones
		for (let i = 0; i < Math.min(3, zoneDataArray.length); i++) {
			topThreeValues[i] = zoneDataArray[i].zoneValue;
			topThreeZones[i] = zoneDataArray[i].zoneTitle;
		}

		// Sort the array by zoneValue in ascending order for bottom
		zoneDataArray.sort((a, b) => (value === 'top' ? b.zoneValue - a.zoneValue : a.zoneValue - b.zoneValue));

		// Get bottom three zones
		for (let i = 0; i < Math.min(3, zoneDataArray.length); i++) {
			bottomThreeValues[i] = zoneDataArray[i].zoneValue;
			bottomThreeZones[i] = zoneDataArray[i].zoneTitle;
		}

		const [distinctUsers, appEngagements, totalAttentionMin, avgAttentionMinPerUser] = props.totalData;

		// Push data for top or bottom three zones based on value and view
		const zonesToPush = value === 'top' ? topThreeZones : bottomThreeZones;

		zonesToPush.forEach((zone) => {
			// Fetch values from respective data sets based on view
			const usersValue = view === 'Zones' ? distinctUsers.zoneTotal[zone] : distinctUsers.appTotal[zone];
			const engagementsValue = view === 'Zones' ? appEngagements.zoneTotal[zone] : appEngagements.appTotal[zone];
			const attentionMinutesValue = view === 'Zones' ? totalAttentionMin.zoneTotal[zone] : totalAttentionMin.appTotal[zone];
			const timePerUserValue = view === 'Zones' ? avgAttentionMinPerUser.zoneTotal[zone] : avgAttentionMinPerUser.appTotal[zone];

			// Replace -Infinity and Infinity with 0
			const users = usersValue === -Infinity || usersValue === Infinity ? 0 : usersValue;
			const engagements = engagementsValue === -Infinity || engagementsValue === Infinity ? 0 : engagementsValue;
			const attentionMinutes = attentionMinutesValue === -Infinity || attentionMinutesValue === Infinity ? 0 : attentionMinutesValue;
			const timePerUser = typeof timePerUserValue === 'string' ? timePerUserValue : timePerUserValue === -Infinity || timePerUserValue === Infinity ? 0 : timePerUserValue;

			// Push the sanitized data object into the data array
			data.push({
				name: zone,
				users,
				engagements,
				attentionMinutes,
				timePerUser,
			});
		});
	} else {
		// Sample data if props or props.data is undefined
		data = [
			{
				name: 'Item 1',
				users: 100,
				engagements: 500,
				attentionMinutes: 200,
				timePerUser: '2 min',
			},
			{
				name: 'Item 2',
				users: 150,
				engagements: 700,
				attentionMinutes: 300,
				timePerUser: '1.5 min',
			},
			{
				name: 'Item 3',
				users: 120,
				engagements: 600,
				attentionMinutes: 250,
				timePerUser: '2.1 min',
			},
		];
	}

	return (
		<div className="table-view-container">
			{props && props.data && <h3 className="table-title">{titleForTable}</h3>}
			<table className="table-view">
				<thead>
					<tr>
						<th className="name-column">Name</th>
						<th className="users-column">Users</th>
						<th className="engagements-column">Engagements</th>
						<th className="attention-column">Att. Minutes</th>
						<th className="time-column">Min Per User</th>
					</tr>
				</thead>
				<tbody>
					{data.map((item, index) => (
						<tr key={index}>
							<td className="name-cell">{item.name}</td>
							<td>{item.users}</td>
							<td>{item.engagements}</td>
							<td>{item.attentionMinutes}</td>
							<td>{item.timePerUser}</td>

							{/*LEAVING IN THE COLOR DEFINED TABLE METRICS FUNCTION. SWITCH OUT ABOVE <td>s for BELOW*/}
							{/*
              <td style={{ color: metricColors.users }}>{item.users}</td>
              <td style={{ color: metricColors.engagements }}>
                {item.engagements}
              </td>
              <td style={{ color: metricColors.attentionMinutes }}>
                {item.attentionMinutes}
              </td>
              <td style={{ color: metricColors.timePerUser }}>
                {item.timePerUser}
              </td>*/}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default TableView;
