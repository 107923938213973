import { BehaviorSubject } from "rxjs"

class QueryService {
	get curData$() {
		return this._curData$.asObservable();
	}

	constructor() {
		this._curData$ = new BehaviorSubject(null);
		// Initialize any properties or dependencies here
	}

	// Add your methods here
	// Example:
	setCurData(data) {
		this._curData$.next(data);
	}
}

export const queryService = new QueryService();