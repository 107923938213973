import React from 'react';
import './app.css';
import '../../style.css';

import Landing from '../landing/landing';
import Login from '../login/login';

import { useAuth0 } from '@auth0/auth0-react';

const App = () => {
	const { user, isAuthenticated, loginWithRedirect, logout, isLoading } =
		useAuth0();

	if (isLoading) {
		return <div>Loading...</div>;
	}

	return (
		<div className="main-app">
			{isAuthenticated && (
				<Landing
					app_metadata={user['https://app.trumetrix.com/app_metadata']}
				></Landing>
			)}
			{!isAuthenticated && <Login></Login>}
		</div>
	);
};

export default App;
