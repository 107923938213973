import React, { useEffect } from 'react';
import './landing.css';

import { Subscription, last } from 'rxjs';

import { fetchService } from '../../services/fetch.service';


import ShowView from '../pages/landing-views/show-view';
import ZoneView from '../pages/landing-views/zone-view';

import { dataService } from '../../services/data.service';
import { queryService } from '../../services/query.service';
import DashboardMetricsButton from '../dashboard-metrics-button/dashboard-metrics-button';
import Dropdown from '../pages/dropdown/dropdown';

let zoneData = [];

let appsData = [];


class Landing extends React.Component {
	constructor(props) {
		super(props);
		this.scrollView = React.createRef();

		this.subscribes = new Subscription();

		this.state = {
			timeRange: null,
			hourRange: [9, 17],
			lastData: null,
			variableDashboardMetrics: {},
			tableViewMetrics: {},
			zoneVariableMetrics: {},
			timeZone: 0,
			appsData: [],
			zoneData: [],
			showView: true,
			dataIndex: 0,
			queries: [],
		};
		this.show = 'PAS';
	}

	componentDidUpdate(prevProps, prevState) {
		if (!this.state.lastData) {
			return;
		}

		if (prevState.lastData !== this.state.lastData) {
			let data = this.state.lastData;
			let { hourRange, timeRange, pairingData, mapInfo } = this.state;

			let zones = zoneData;
			let apps = appsData;
			apps.concat(zones);

			/**
			 * Region: Data Processing
			 * The data here is processed on three dimensions:
			 * 1. Data Type
			 * 2. Hourly vs Total
			 * 3. By Show, App, or Zone
			 *
			 * This comes to a total of 24 different data sets.
			 */

			/*
			 * Data 1: Distinct Users
			 */

			// Apps Total
			this.distinctUsersByAppTotal = dataService.getDistinctUsersByApp(apps, data, hourRange, timeRange);

			// Apps by Hour
			this.distinctUsersByAppByHour = dataService.getDistinctUsersByAppByHour(apps, data, hourRange, timeRange);

			// Zones Total
			this.distinctUserByZoneTotal = dataService.getDistinctUsersByZone(zones, data, hourRange, timeRange);

			// Zones by Hour
			this.distinctUserByZoneTotalByHour = dataService.getDistinctUsersByZoneByHour(zones, data, hourRange, timeRange);

			// Shows Total
			this.distinctUsersByShowTotal = dataService.getDistinctUsersByShow(this.distinctUserByZoneTotal);

			// Shows by Hour
			this.distinctUsersByShowByHour = dataService.getDistinctUsersByShowByHour(this.distinctUserByZoneTotal, this.distinctUserByZoneTotalByHour);

			// Visitors to the show
			this.visitorsByShowTotal = dataService.getVisitorsByShow(zones, data, hourRange, timeRange);

			// Visitors to the show by Hour
			this.visitorsByShowByHour = dataService.getVisitorsByShowByHour(zones, data, hourRange, timeRange);

			/*
			 * Data 2: Average Attention
			 */

			// App Total
			this.averageAttentionByAppTotal = dataService.getAppTimePerUser(apps, data, hourRange, timeRange);

			// App by Hour
			this.averageAttentionByAppByHour = dataService.getAppTimePerUserByHour(apps, data, hourRange, timeRange);

			// Zone Total
			this.averageAttentionByZoneTotal = dataService.getZoneTimePerUser(zones, data, hourRange, timeRange);

			// Zone by Hour
			this.averageAttentionByZoneByHour = dataService.getZoneTimePerUserByHour(zones, data, hourRange, timeRange);

			// Show Total
			this.averageAttentionByShowTotal = dataService.getShowTimePerUser(zones, data, hourRange, timeRange);

			// Show Hourly
			this.averageAttentionByShowByHour = dataService.getShowTimePerUserByHour(zones, data, hourRange, timeRange);

			/*
			 * Data 3: Engagement
			 */

			// App Total
			this.engagementsByAppTotal = dataService.getEngagementsByApp(apps, data, hourRange, timeRange);

			// App by Hour
			this.engagementsByAppByHour = dataService.getEngagementsByAppByHour(apps, data, hourRange, timeRange);

			// Zone Total
			this.engagementsByZoneTotal = dataService.getEngagementsByZone(zones, data, hourRange, timeRange, pairingData);

			// Zone by Hour
			this.engagementsByZoneByHour = dataService.getEngagementsByZoneByHour(zones, data, hourRange, timeRange, pairingData);

			// Show Total
			this.engagementsByShowTotal = dataService.getEngagementsByShow(this.engagementsByZoneTotal);

			// Show by Hour
			this.engagementsByShowByHour = dataService.getEngagementsByShowByHour(this.engagementsByZoneByHour);

			/*
			 * Dimension 4: Attention Minutes
			 */

			// App Total
			this.totalAttentionMinutesPerApp = dataService.getAppTimeTotals(apps, data, hourRange, timeRange);

			// App by Hour
			this.totalAttentionMinutesPerAppByHour = dataService.getAppTimeTotalsByHour(apps, data, hourRange, timeRange);

			// Zone Total
			this.totalAttentionMinutesPerZone = dataService.getZoneTimeTotals(zones, data, hourRange, timeRange);

			// Zone by Hour
			this.totalAttentionMinutesPerZoneByHour = dataService.getZoneTimeTotalsByHour(zones, data, hourRange, timeRange);

			// Show Total
			this.totalAttentionMinutesPerShow = dataService.getTotalAttentionMinutesPerShow(this.totalAttentionMinutesPerZone);

			// Show by Hour
			this.totalAttentionMinutesPerShowByHour = dataService.getTotalAttentionMinutesPerShowByHour(this.totalAttentionMinutesPerZoneByHour);

			this.csvData = dataService.getCSVforZones(
				{
					zones: {
						totalUsers: this.distinctUserByZoneTotal,
						totalEngagements: this.engagementsByZoneTotal,
						totalAttentionMinutes: this.totalAttentionMinutesPerZone,
						averageAttention: this.averageAttentionByZoneTotal,
					},
					total: {
						totalUsers: this.distinctUsersByShowTotal,
						totalEngagements: this.engagementsByShowTotal,
						totalAttentionMinutes: this.totalAttentionMinutesPerShow,
						averageAttention: this.averageAttentionByShowTotal,
						totalVisitors: this.visitorsByShowTotal
					},
					hourly: {
						totalUsers: this.visitorsByShowByHour,
						totalEngagements: this.engagementsByShowByHour,
						totalAttentionMinutes: this.totalAttentionMinutesPerShowByHour,
						averageAttention: this.averageAttentionByShowByHour,
					}
				}
			);
			//fetchService.fetchCSV(this.show + '_' + this.day, this.csvData);

			this.buttons = [
				{
					title: 'Users',
					hourlyValue: this.distinctUsersByShowByHour,
					totalValue: this.distinctUsersByShowTotal,
					zoneTotal: this.distinctUserByZoneTotal,
					appTotal: this.distinctUsersByAppTotal,
					zoneView: this.distinctUserByZoneTotalByHour,
					icon: '../../assets/img/icons/button-icons/Distinct_Users_Icon.svg',
					subtitle: 'Number Total',
				},

				{
					title: 'Engagements',
					hourlyValue: this.engagementsByShowByHour,
					totalValue: this.engagementsByShowTotal,
					zoneTotal: this.engagementsByZoneTotal,
					appTotal: this.engagementsByAppTotal,
					zoneView: this.engagementsByZoneByHour,
					icon: '../../assets/img/icons/button-icons/Engagements_Icon.svg',
					subtitle: 'Number Total',
				},
				{
					title: 'Attention Minutes',
					hourlyValue: this.totalAttentionMinutesPerShowByHour,
					totalValue: this.totalAttentionMinutesPerShow,
					zoneTotal: this.totalAttentionMinutesPerZone,
					appTotal: this.totalAttentionMinutesPerApp,
					zoneView: this.totalAttentionMinutesPerZoneByHour,
					icon: '../../assets/img/icons/button-icons/Total_Time_Icon.svg',
					subtitle: 'Number Total',
				},
				{
					title: 'Average Attention',
					hourlyValue: this.averageAttentionByShowByHour,
					totalValue: Math.round(this.averageAttentionByShowTotal * 100) / 100, // nearest hundreth
					zoneTotal: this.averageAttentionByZoneTotal,
					appTotal: this.averageAttentionByAppTotal,
					zoneView: this.averageAttentionByZoneByHour,
					icon: '../../assets/img/icons/button-icons/Average_Time_Icon.svg',
					subtitle: 'Minutes Per User',
				},
			];

			/** End Region */

			/**
			 * Region: User Traffic Metrics
			 */

			// FromTo Zone Traffic
			this.fromToZoneTraffic = dataService.getFromToZoneTraffic(data, mapInfo);

			/**
			 * End Region
			 */

			// this.averageZonesPerUserPerHour = dataService.getAverageZonesVisitedPerUserByHour(zones, data, hourRange, timeRange);

			this.hourlyBreakdown = dataService.getHourlySummaryOfUserZoneTravel(zones, data, hourRange, timeRange);

			this.changeVariableDashboardMetrics(0);

			this.forceUpdate();
		}
	}

	componentDidMount() {
		this.subscribes.add(
			fetchService.data$.subscribe((query) => {
				if (query != null && query.data.status == 1) {
					this.setState({
						lastData: query.data.data,
						pairingData: query.data.pairingData || this.state.pairingData,
						timeRange: query.time || this.state.timeRange,
						timeZone: query.timeZone,
						appsData: query.apps,
						zoneData: query.zones,
						mapInfo: query.mapInfo,
						showName: query.showName,
					});

					queryService.setCurData({
						lastData: query.data.data,
						pairingData: query.data.pairingData || this.state.pairingData,
						timeRange: query.time || this.state.timeRange,
						timeZone: query.timeZone,
						appsData: query.apps,
						zoneData: query.zones,
						mapInfo: query.mapInfo,
						showName: query.showName,
					});

					zoneData = [];
					appsData = [];

					let permissions = {
						zonePermissions: {},
						appPermissions: {},
					};

					this.props.app_metadata.zoneData.forEach((zone) => {
						permissions.zonePermissions[zone] = true;
					});
					this.props.app_metadata.appsData.forEach((app) => {
						permissions.appPermissions[app] = true;
					});

					query.zones.forEach((zone, idx) => {
						zoneData.push({ id: idx, title: zone });
					});

					query.apps.forEach((app, idx) => {
						appsData.push({ id: idx, title: app });
					});

					this.forceUpdate();

					if (query.data.errors?.length > 0) {
						console.log(query.data.errors);
					}
				} else if (query != null && query.data.status == 2) {
					this.setState({ lastData: null, updateCalled: false });
				}
			}),
			fetchService.queries$.subscribe((queries) => {
				if (queries != null) {
					this.setState({ queries: queries });
				}
			}),
			queryService.curData$.subscribe((data) => {
				// Do things that you need to do when the data is updated
			})
		);

		// Add scroll event listener
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		// Remove scroll event listener
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll = () => {
		const scrollY = window.scrollY || document.documentElement.scrollTop;
		const container = this.scrollView.current;
		if (!container) return;

		if (scrollY > 100) {
			// Adjust this value as needed
			container.style.position = 'fixed';
			container.style.top = '0'; // Adjust this value as needed
			container.style.opacity = '0.75'; // 60% transparency
		} else {
			container.style.position = 'static'; // Return to normal flow
			container.style.opacity = '1'; // Full opacity
		}
	};

	render() {
		let apps = appsData;
		let zones = zoneData;

		let queries = this.state.queries || [];

		let showViewCharts = {
			// area map aka variable
			tableViewMetrics: this.state.tableViewMetrics,
			variableDashboardMetrics: this.state.variableDashboardMetrics,

			// the six maps below variable
			avgZonesPerUserByHour: {}, // todo
			avgAppsPerUserByHour: {}, // todo
			avgAttentionMinutesPerZone: this.averageAttentionByZoneTotal,
			distinctUsersPerZone: this.distinctUserByZoneTotal,
			engagedUsersPerZone: this.engagementsByZoneTotal,
			totalAttentionMinutesPerZone: this.totalAttentionMinutesPerZone,
		};

		let showViewProps = {
			apps,
			zones,
			timezone: this.state.timeZone,
			pairingData: this.state.pairingData,
			estimatedStaff: this.state.estimatedStaff,
			timeRange: this.state.timeRange,
			hourRange: this.state.hourRange,
			data: this.state.lastData,

			chartProps: showViewCharts,

			highLowAvgZonesPerUser: this.highLowAvgZonesPerUser,
			dataIndex: this.state.dataIndex,
			// timePerZone : this.
		};

		let zoneViewCharts = {
			// tables
			tableViewMetrics: this.state.tableViewMetrics,

			// 4 dashboards below the sankey
			engagedUsersPerApp: this.engagementsByAppTotal,
			totalAttentionMinPerApp: this.totalAttentionMinutesPerApp,
			avgAttentionMinPerApp: this.averageAttentionByAppTotal,
			distinctUserTotalsByApp: this.distinctUsersByAppTotal,
			variableDashboardMetrics: this.state.zoneVariableMetrics,

			networkGraphMetrics: this.fromToZoneTraffic,
		};
		let zoneViewProps = {
			data: this.state.lastData,

			zoneViewCharts: zoneViewCharts,
		};

		return (
			// this is where everything is actually rendered
			<div className="container-main">
				<div className="container-top-bar">
					<div className="container-show-choices">
						<Dropdown
							id="dropdown-menu"
							key="container-show-dropdown"
							props={{ shows: queries }}
							onClick={(show) => {
								this.selectQuery(show);
							}}
						></Dropdown>

						<button
							className={!this.state.showView ? "landing-change-view" : "landing-change-view selected"}
							onClick={() => {
								this.setView(false);
							}}
						>
							<span>Show View</span>
						</button>
						<button
							className={this.state.showView ? "landing-change-view" : "landing-change-view selected"}
							onClick={() => {
								this.setView(true);
							}}
						>
							<span>Zone View</span>
						</button>
					</div>

					<div className="container-change-view">
						<img id="logo" src="../../assets/img/background-images/TruMetrix_NavBar_Logo.svg" />
					</div>
				</div>

				<div className="container-dashboard-main">
					{this.state.lastData ? (
						// TODO MUI
						<div className="container-dashboard-views">
							<div
								className="container-dashboard-options"
								ref={this.scrollView}
							>
								<h2>{this.state.showName}</h2>
								<div className="container-dashboard-buttons">
									{this.buttons?.map(({ title, hourlyValue, totalValue, icon, subtitle }, idx) => {
										return <DashboardMetricsButton idx={idx} title={title} subtitle={subtitle} totalValue={totalValue} icon={icon} changeVariableDashboardMetrics={this.changeVariableDashboardMetrics.bind(this)}></DashboardMetricsButton>;
									})}

									<div className="container-dashboard-info">
										<div className='total-visitors'>
											<h2>Total Visitors: {this.visitorsByShowTotal}</h2>
										</div>
										<button onClick={this.exportCSV}>Export CSV</button>
									</div>
								</div>

							</div>
							{this.state.showView ? (
								<div key="landing-show-view" className="landing-show-view">
									<ShowView props={showViewProps}></ShowView>
								</div>
							) : (
								<div key="landing-zone-view" className="landing-zone-view">
									<ZoneView props={zoneViewProps}></ZoneView>
								</div>
							)}
						</div>
					) : (
						<div id="messaging">{this.state.updateCalled ? 'Updating...' : 'Please select a Show'}</div>
					)}
				</div>
			</div>
		);
	}

	setView(showView) {
		this.setState({ showView: !showView });
	}

	removeStaff(e) {
		this.setState({ estimatedStaff: e.target.value });
	}

	changeVariableDashboardMetrics = (idx) => {
		// console.log('HOURLY', hourlyValue, typeof hourlyValue);
		let metrics = this.buttons[0];
		let dataIndex = 0;
		if (idx != undefined) {
			metrics = this.buttons[idx];
			dataIndex = idx + 1;
		}
		const { title, hourlyValue, totalValue, zoneTotal, appTotal, zoneView } = metrics;
		let totalData = this.buttons;

		switch (dataIndex) {
			case 1:
				this.distinctUserByZoneTotal = sortData(this.distinctUserByZoneTotal);

				this.engagementsByZoneTotal = sortDataSameOrder(this.engagementsByZoneTotal, this.distinctUserByZoneTotal);
				this.totalAttentionMinutesPerZone = sortDataSameOrder(this.totalAttentionMinutesPerZone, this.distinctUserByZoneTotal);
				this.averageAttentionByZoneTotal = sortDataSameOrder(this.averageAttentionByZoneTotal, this.distinctUserByZoneTotal);

				this.distinctUsersByAppTotal = sortData(this.distinctUsersByAppTotal);

				this.engagementsByAppTotal = sortDataSameOrder(this.engagementsByAppTotal, this.distinctUsersByAppTotal);
				this.totalAttentionMinutesPerApp = sortDataSameOrder(this.totalAttentionMinutesPerApp, this.distinctUsersByAppTotal);
				this.averageAttentionByAppTotal = sortDataSameOrder(this.averageAttentionByAppTotal, this.distinctUsersByAppTotal);
				break;
			case 2:
				this.engagementsByZoneTotal = sortData(this.engagementsByZoneTotal);

				this.distinctUserByZoneTotal = sortDataSameOrder(this.distinctUserByZoneTotal, this.engagementsByZoneTotal);
				this.totalAttentionMinutesPerZone = sortDataSameOrder(this.totalAttentionMinutesPerZone, this.engagementsByZoneTotal);
				this.averageAttentionByZoneTotal = sortDataSameOrder(this.averageAttentionByZoneTotal, this.engagementsByZoneTotal);

				this.engagementsByAppTotal = sortData(this.engagementsByAppTotal);

				this.distinctUsersByAppTotal = sortDataSameOrder(this.distinctUsersByAppTotal, this.engagementsByAppTotal);
				this.totalAttentionMinutesPerApp = sortDataSameOrder(this.totalAttentionMinutesPerApp, this.engagementsByAppTotal);
				this.averageAttentionByAppTotal = sortDataSameOrder(this.averageAttentionByAppTotal, this.engagementsByAppTotal);
				break;
			case 3:
				this.totalAttentionMinutesPerZone = sortData(this.totalAttentionMinutesPerZone);

				this.engagementsByZoneTotal = sortDataSameOrder(this.engagementsByZoneTotal, this.totalAttentionMinutesPerZone);
				this.distinctUserByZoneTotal = sortDataSameOrder(this.distinctUserByZoneTotal, this.totalAttentionMinutesPerZone);
				this.averageAttentionByZoneTotal = sortDataSameOrder(this.averageAttentionByZoneTotal, this.totalAttentionMinutesPerZone);

				this.totalAttentionMinutesPerApp = sortData(this.totalAttentionMinutesPerApp);

				this.engagementsByAppTotal = sortDataSameOrder(this.engagementsByAppTotal, this.totalAttentionMinutesPerApp);
				this.distinctUsersByAppTotal = sortDataSameOrder(this.distinctUsersByAppTotal, this.totalAttentionMinutesPerApp);
				this.averageAttentionByAppTotal = sortDataSameOrder(this.averageAttentionByAppTotal, this.totalAttentionMinutesPerApp);
				break;
			case 4:
				this.averageAttentionByZoneTotal = sortData(this.averageAttentionByZoneTotal);

				this.engagementsByZoneTotal = sortDataSameOrder(this.engagementsByZoneTotal, this.averageAttentionByZoneTotal);
				this.distinctUserByZoneTotal = sortDataSameOrder(this.distinctUserByZoneTotal, this.averageAttentionByZoneTotal);
				this.totalAttentionMinutesPerZone = sortDataSameOrder(this.totalAttentionMinutesPerZone, this.averageAttentionByZoneTotal);

				this.averageAttentionByAppTotal = sortData(this.averageAttentionByAppTotal);

				this.engagementsByAppTotal = sortDataSameOrder(this.engagementsByAppTotal, this.averageAttentionByAppTotal);
				this.distinctUsersByAppTotal = sortDataSameOrder(this.distinctUsersByAppTotal, this.averageAttentionByAppTotal);
				this.totalAttentionMinutesPerApp = sortDataSameOrder(this.totalAttentionMinutesPerApp, this.averageAttentionByAppTotal);
				break;
		}


		this.setState({
			variableDashboardMetrics: { title: title, data: hourlyValue },
			tableViewMetrics: {
				title: title,
				zoneData: zoneTotal,
				appData: appTotal,
				totalData: totalData,
			},
			zoneVariableMetrics: { title: title, data: zoneView },
			dataIndex: dataIndex,
		});
	};

	selectQuery(query) {

		this.setState({ updateCalled: true, lastData: null });

		this.show = query.split(' ')[0];
		this.day = query.split(' ')[1];
		if (query.split(' ')[2]) {
			this.day += ' ' + query.split(' ')[2];
		}
		console.log('QUERY', query);
		fetchService.fetchPreQuery(query);
	}

	exportCSV = () => {
		fetchService.fetchCSV(this.show + '_' + this.day, this.csvData);
	}


}

function sortData(dict) {
	// Step 1: Convert the dictionary into an array of key-value pairs
	let items = Object.entries(dict);

	// Step 2: Sort the array based on the values
	items.sort((a, b) => b[1] - a[1]);

	// Step 3: Optionally, convert the sorted array back into an object
	dict = Object.fromEntries(items);

	return dict;
}

function sortDataSameOrder(dictToSort, dictToMatch) {
	let sortedDict = {};
	Object.keys(dictToMatch).forEach((key) => {
		sortedDict[key] = dictToSort[key];
	});
	return sortedDict;
}

export default Landing;

