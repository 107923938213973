import React, { useEffect, useRef, useState } from "react";
import "./network-graph.css"; // Import CSS file
import { queryService } from "../../../services/query.service";
import { configService } from "../../../services/config.service";
import { isPointInPolygon } from "../../../services/utility.service";

const colors = ["#27BBF4"];
const fontColor = "#5F6064";

const weights = {
	link: 0.0025,
	node: 0.025,
};

const NetworkGraph = ({ nodes, links }) => {
	const canvasRef = useRef(null);
	const [selectedZone, setSelectedZone] = useState(null);
	const [hoveredZone, setHoveredZone] = useState(null);

	const [curData, setCurData] = useState(null); // State to hold data from subscription
	const [config, setConfig] = useState(null); // State to hold data from subscription

	useEffect(() => {
		const canvas = canvasRef.current;
		const ctx = canvas.getContext("2d");

		if (curData && curData.showName) {
			const img = new Image();
			img.src = config.dataURL + "/api/image/" + curData.showName + ".jpg";
			let jsonData = curData.mapInfo;
			img.onload = () => {
				// Set canvas dimensions based on image size
				canvas.width = img.width;
				canvas.height = img.height;

				// Draw the image on canvas
				ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

				// Function to draw zones on canvas
				const drawZones = () => {
					let index = 1;
					links.forEach((item) => {
						const { from, to, normalizedValue } = item;

						let fromNode = nodes.find((node) => node.name === from);
						let toNode = nodes.find((node) => node.name === to);

						const lineWidth = canvas.width * weights.link * normalizedValue * 5;
						const value = Math.trunc(normalizedValue * 255);
						const hexValue = value.toString(16).padStart(2, "0");
						let rgbaColor = `${colors[0]}${hexValue}`;

						ctx.strokeStyle = rgbaColor;

						if (fromNode && toNode) {
							ctx.beginPath();
							ctx.moveTo(fromNode.x * canvas.width, fromNode.y * canvas.height);
							ctx.lineTo(toNode.x * canvas.width, toNode.y * canvas.height);
							ctx.lineWidth = lineWidth;
							ctx.stroke();
						}
					});
					nodes.forEach((item) => {
						const { x, y, name, normalizedValue } = item;

						const value = Math.trunc(normalizedValue * 255);
						const hexValue = value.toString(16).padStart(2, "0");
						let rgbaColor = `${colors[0]}${hexValue}`;

						// Draw lines to connect points
						ctx.strokeStyle = colors[0];
						ctx.fillStyle = rgbaColor;

						// Convert to canvas coordinates
						let scaledX = x * canvas.width;
						let scaledY = y * canvas.height;

						const radius = canvas.width * weights.node * item.normalizedValue;
						ctx.beginPath();
						ctx.arc(scaledX, scaledY, radius, 0, 2 * Math.PI);
						ctx.fill();
						ctx.stroke();

						const fontSize = canvas.width * 0.025;
						ctx.font = `bold ${fontSize}px Arial, sans-serif`;
						ctx.fillStyle = fontColor; // Ensure the font color is red
						ctx.textAlign = "center";
						if (!hoveredZone || hoveredZone.name != name) {
							ctx.strokeStyle = "black"; // Border color
							ctx.lineWidth = 1; // Border width
							// First, draw the text outline
							ctx.strokeText(index, scaledX, scaledY);
							ctx.fillText(index, scaledX, scaledY); // Adjusted position to 5% left
						}

						index++;
					});
				};

				// Initial draw of zones
				drawZones();

				// Handle canvas hover and mouseout events
				const handleCanvasHover = (e) => {
					const rect = canvas.getBoundingClientRect();
					const mouseX = e.clientX - rect.left;
					const mouseY = e.clientY - rect.top;

					let foundHover = false;
					jsonData.forEach((item) => {
						const { scaledPoints } = item;
						// Calculate the centroid of the polygon
						const centroid = scaledPoints.reduce((acc, point) => {
							return {
								x: acc.x + (point.x * canvas.offsetWidth),
								y: acc.y + (point.y * canvas.offsetHeight)
							};
						}, { x: 0, y: 0 });

						centroid.x /= scaledPoints.length;
						centroid.y /= scaledPoints.length;

						// Calculate the distance from the centroid to the mouse point
						const distance = Math.sqrt(Math.pow(centroid.x - mouseX, 2) + Math.pow(centroid.y - mouseY, 2));

						// Assuming you have a defined threshold distance
						const thresholdDistance = 20; // Change this value to your desired threshold

						if (distance <= thresholdDistance) {
							setHoveredZone(item);
							foundHover = true;
						}
					});

					if (!foundHover) {
						setHoveredZone(null); // Clear hovered zone
					} else {
						// Redraw zones with highlighted effect
						ctx.clearRect(0, 0, canvas.width, canvas.height);
						ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
						drawZones();

						// Display zone name next to hovered zone
						if (hoveredZone) {
							const { scaledPoints } = hoveredZone;
							const zoneCenterX =
								(scaledPoints.reduce((acc, cur) => acc + cur.x, 0) /
									scaledPoints.length) *
								canvas.width;
							const zoneCenterY =
								(scaledPoints.reduce((acc, cur) => acc + cur.y, 0) /
									scaledPoints.length) *
								canvas.height;

							const fontSize = canvas.width * 0.03;
							ctx.font = `bold ${fontSize}px Inter Arial, sans-serif`;
							ctx.fillStyle = "black"; // Ensure the font color is red
							ctx.strokeStyle = "black"; // Border color
							ctx.lineWidth = 1; // Border width
							// First, draw the text outline
							ctx.strokeText(hoveredZone.name, zoneCenterX, zoneCenterY);
							ctx.fillText(hoveredZone.name, zoneCenterX, zoneCenterY); // Adjusted position to 5% left
						}
					}
				};

				canvas.addEventListener("mousemove", handleCanvasHover);

				canvas.addEventListener("mouseout", () => {
					// Clear canvas on mouse out
					ctx.clearRect(0, 0, canvas.width, canvas.height);
					ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
					drawZones(); // Redraw zones
					setHoveredZone(null); // Clear hovered zone
				});

				return () => {
					// Clean up event listener
					canvas.removeEventListener("mousemove", handleCanvasHover);
				};
			};
		}

		const querySub = queryService.curData$.subscribe((data) => {
			setCurData(data); // Update local state with new data
			// You can get the map image from your config's dataURL + "/api/image/" data.showName + ".jpg"
			// You can get your map information (replace jsonData) from data.mapInfo
		});

		const configSub = configService.config$.subscribe((config) => {
			setConfig(config); // Update local state with new config
			// Here is where you can get the dataURL from the config
			// It will be config.dataURL
		});

		// Return cleanup function
		return () => {
			// Unsubscribe from both subscriptions
			querySub.unsubscribe();
			configSub.unsubscribe();
		};
	}, [selectedZone, hoveredZone, queryService, configService, config, curData]);

	// Handle legend item click
	const handleLegendItemClick = (zone) => {
		setSelectedZone(zone === selectedZone ? null : zone);
	};

	return (
		<div className="network-main">
			<canvas ref={canvasRef} className="network-map" />
		</div>
	);
};

export default NetworkGraph;
