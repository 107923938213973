import React, { useRef, useEffect, useState } from "react";
import "./zone-view.css";
import ChartComponent from "../../highcharts/base-card-functional";
import { CHART_TYPES } from "../../../enums/types.enum";

import NetworkGraph from "../network-graph/network-graph";
import TableView from "../table-view/table-view";
import SankeyDiagram from "../../highcharts/sankey-diagram/sankey-diagram";

import ZoneEngagedUsersChart from "../../highcharts/column-charts/zone-view/zone-engaged-users";
import ZoneTotalAttentionChart from "../../highcharts/column-charts/zone-view/zone-total-attention";
import ZoneAvgAttentionChart from "../../highcharts/column-charts/zone-view/zone-avg-attention";
import ZoneDistinctUsersChart from "../../highcharts/column-charts/zone-view/zone-distinct-users";
import StackedAreaChart from "../../highcharts/area-chart/stacked-area-chart";
import BarChart from "../../highcharts/bar-chart/bar-chart";

const ZoneView = ({ props: { data, zoneViewCharts } }) => {
	const {
		tableViewMetrics,
		engagedUsersPerApp,
		totalAttentionMinPerApp,
		avgAttentionMinPerApp,
		distinctUserTotalsByApp,
		zonePathingPerUser,
		variableDashboardMetrics,
		networkGraphMetrics,
	} = zoneViewCharts;

	const showVariableProps = {
		distinctUserTotalsByApp: distinctUserTotalsByApp,
	};

	const engagedUsersPerAppProps = {
		data: engagedUsersPerApp,
		CHART_TYPES: CHART_TYPES.COLUMN,
		xAxisName: "App",
		yAxisName: "Engagements",
		dataType: "engagements",
	};

	const totalAttentionMinPerAppProps = {
		data: totalAttentionMinPerApp,
		CHART_TYPES: CHART_TYPES.COLUMN,
		xAxisName: "App",
		yAxisName: "Total Attention Min",
		dataType: "attentionMinutes",
	};

	const avgAttentionMinPerAppProps = {
		data: avgAttentionMinPerApp,
		CHART_TYPES: CHART_TYPES.COLUMN,
		xAxisName: "App",
		yAxisName: "Avg Attention Min",
		dataType: "averageAttention",
	};

	const distinctUserTotalsByAppProps = {
		data: distinctUserTotalsByApp,
		chartType: CHART_TYPES.COLUMN,
		xAxisName: "App",
		yAxisName: "Distinct Users",
		dataType: "distinctUsers",
	};

	const tableViewProps = {
		data: tableViewMetrics.appData,
		title: tableViewMetrics.title,
		totalData: tableViewMetrics.totalData,
		view: "Apps",
	};

	const variableDashboardProps = {
		data: variableDashboardMetrics.data,
		chartType: CHART_TYPES.AREA,
		yAxisName: variableDashboardMetrics.title,
		xAxisName: "Hour",
		view: "Apps",
	};

	const networkGraphProps = {
		links: networkGraphMetrics?.links,
		nodes: networkGraphMetrics?.nodes,
		lowestValue: networkGraphMetrics?.lowestValue,
	};

	const parentRefNetworkMap = useRef(null);
	const parentRefCohort = useRef(null);
	const [parentHeightMap, setParentHeightMap] = useState(0);
	const [parentHeightCohort, setParentHeightCohort] = useState(0);

	useEffect(() => {
		if (parentRefNetworkMap.current) {
			setParentHeightMap(parentRefNetworkMap.current.clientHeight);
		}

		if (parentRefCohort.current) {
			setParentHeightCohort(parentRefCohort.current.clientHeight);
		}
	}, [parentRefNetworkMap, parentRefCohort]);

	return (
		<div key="zone-main" className="zone-main">
			<div
				key="zone-map-all"
				className="zone-map-all"
				ref={parentRefNetworkMap}
			>
				<div key="zone-map" className="zone-map">
					<NetworkGraph
						links={networkGraphMetrics?.links}
						nodes={networkGraphMetrics?.nodes}
					/>
				</div>

				<div key="zone-top-bottom" className="zone-top-bottom">
					<div key="zone-top" className="zone-top">
						<TableView props={tableViewProps} value="top"></TableView>
					</div>
					<div key="zone-bottom" className="zone-bottom">
						<TableView props={tableViewProps}></TableView>
					</div>
				</div>
			</div>

			{/* <div className="zone-counts-and-cohorts" key="zone-counts-and-cohorts" ref={parentRefCohort}>
        <SankeyDiagram></SankeyDiagram>
      </div> */}

			<div className="zone-variable-dashboard" key="zone-variable-dashboard">
				<StackedAreaChart
					props={variableDashboardProps}
					chartType={CHART_TYPES.AREA}
				></StackedAreaChart>
			</div>

			<div key="zone-engaged-time-users" className="zone-engaged-time-users">
				<BarChart
					props={distinctUserTotalsByAppProps}
				></BarChart>
				<BarChart
					props={engagedUsersPerAppProps}
				></BarChart>
			</div>

			<div key="zone-attention-distinct" className="zone-attention-distinct">
				<BarChart
					props={totalAttentionMinPerAppProps}
				></BarChart>

				<BarChart
					props={avgAttentionMinPerAppProps}
				></BarChart>
			</div>
		</div>
	);
};

export default ZoneView;
