// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../style.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Assuming this is the main landing page container */\n#app,\n.main-app {\n\t/*\n  min-height: 100vh;\n  max-width: 100%;\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  box-sizing: border-box;\n  padding: 0; \n  margin: 0; \n  box-sizing: border-box;\n  background-color: var(--light-grey);*/\n}\n\n/* Example header styling */\n.header {\n\tpadding: 15px 20px;\n\tbackground-color: #6200ee;\n\tcolor: #fff;\n\t/* Ensure text color is readable */\n}", "",{"version":3,"sources":["webpack://./public/components/app/app.css"],"names":[],"mappings":"AAEA,qDAAqD;AACrD;;CAEC;;;;;;;;;;;uCAWsC;AACvC;;AAEA,2BAA2B;AAC3B;CACC,kBAAkB;CAClB,yBAAyB;CACzB,WAAW;CACX,kCAAkC;AACnC","sourcesContent":["@import '../../style.css';\n\n/* Assuming this is the main landing page container */\n#app,\n.main-app {\n\t/*\n  min-height: 100vh;\n  max-width: 100%;\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  box-sizing: border-box;\n  padding: 0; \n  margin: 0; \n  box-sizing: border-box;\n  background-color: var(--light-grey);*/\n}\n\n/* Example header styling */\n.header {\n\tpadding: 15px 20px;\n\tbackground-color: #6200ee;\n\tcolor: #fff;\n\t/* Ensure text color is readable */\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
