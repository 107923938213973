import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "./area-chart.css";

const AreaChart = ({ props }) => {
	const categoryPairedData = {};

	const xCategories = [];
	const displayData = [];

	// Populate xCategories and displayData from props.data
	for (const [currentHour, value] of Object.entries(props.data || {})) {
		const hour = parseInt(currentHour);
		let hour12 = hour % 12;
		hour12 = hour12 === 0 ? 12 : hour12; // Convert 0 to 12 for 12 am/pm

		const period = hour < 12 ? "am" : "pm";
		const formattedHour = `${hour12} ${period}`;
		xCategories.push(formattedHour);
		displayData.push(value);
	}

	// Set chart color based on yAxisName
	let chartColor;

	chartColor =
		{
			Users: "var(--teal)",
			"Attention Minutes": "var(--orange)",
			Engagements: "var(--green)",
			"Min Per User": "var(--yellow)",
		}[props.yAxisName] || undefined;

	const initialChartOptions = {
		chart: {
			type: "area",
			height: null,
			width: null,
			marginTop: 75, // Increase top margin
			marginRight: 50, // Increase right margin
			marginBottom: 100, // Increase bottom margin
			marginLeft: 75, // Increase left margin
			spacingTop: 10,
			spacingRight: 10,
			spacingBottom: 10,
			spacingLeft: 10,
			backgroundColor: "var(--white)",
		},
		title: {
			text: "Show Data by Hour",
			align: "left",
			x: 10, // Position the title at the left edge
			marginTop: 10,

			style: {
				fontSize: "1em",
				fontFamily: "Inter",
			},
		},
		xAxis: {
			title: {
				text: "" /*set to an empty string to remove labels. Original code commented out below */,
				//text: `<span style="font-size: .8em; font-weight: bold; color: #333;">${
				//  props.xAxisName || "X Axis"
				//}</span>`,
				//useHTML: true,
			},
			categories: xCategories.length ? xCategories : "",
			labels: {
				formatter: function () {
					return this.value.length > 5
						? `${this.value.substring(0, 3)}...`
						: this.value;
				},
				startOnTick: true, // Ensure the axis starts at the beginning
				endOnTick: true, // Ensure the axis ends at the end
				minPadding: 0, // No padding at the beginning of the axis
				maxPadding: 0, // No padding at the end of the axis
			},
		},
		yAxis: {
			title: {
				text: `<span style="font-size: .9em;  color: #333;">${props.yAxisName || "Y Axis"
					}</span>`,
				useHTML: true,
			},
			type: "linear",
		},
		credits: {
			enabled: false,
		},
		series: [
			{
				type: "area",
				data: displayData || [],
				color: chartColor,
			},
		],
		tooltip: {
			headerFormat:
				'<span style="font-size: 1em"><b>{point.key}</b></span><br/>',
			pointFormat:
				'<span style="font-size: 1em; display: block;">{point.y}</span>',
			backgroundColor: "rgba(255, 255, 255, 0.85)",
			borderWidth: 1,
			borderColor: "#ccc",
			shadow: false,
			style: {
				fontSize: "14px",
				fontFamily: "Inter",
			},
			shared: true,
		},
		legend: {
			enabled: false,
		},
	};

	return (
		<div className="showarea-container">
			<HighchartsReact highcharts={Highcharts} options={initialChartOptions} />
		</div>
	);
};

export default AreaChart;
